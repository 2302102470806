import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import Header from "../Components/Header/Header";
import Sidebar from "../Components/Sidebar/Sidebar";
import { getpricingdata, } from "../Api/bookingApi";
import { API_URL } from "../Api/config";

function Pricing() {


    const columns = [
        {
            dataField: "id",
            text: "ID",
            sort: true,
        },
        {
            dataField: "bed",
            text: "Bed",
            sort: true,
            type: "number",
        },
        {
            dataField: "bath",
            text: "Bath",
            sort: true,
            type: "number",
        },
        {
            dataField: "h_w_h",
            text: "HWH",
            type: "number",
            hidden: true,
        },
        {
            dataField: "h_w_p",
            text: "HWP",
            type: "number",
            hidden: true,
        },
        {
            dataField: "h_bw_h",
            text: "HBWH",
            type: "number",
            hidden: true,
        },
        {
            dataField: "h_bw_p",
            text: "HBWP",
            type: "number",
            hidden: true,
        },
        {
            dataField: "h_m_h",
            text: "HOUSE MONTHLY HOURS",
            type: "number",
        },
        {
            dataField: "h_m_p",
            text: "HMP",
            type: "number",
            hidden: true,
        },
        {
            dataField: "g_w_h",
            text: "GWH",
            type: "number",
            hidden: true,
        },
        {
            dataField: "g_w_p",
            text: "GWP",
            type: "number",
            hidden: true,
        },
        {
            dataField: "g_bw_h",
            text: "GENERAL HOURS",
            type: "number",
        },
        {
            dataField: "g_bw_p",
            text: "GBWP",
            type: "number",
            hidden: true,
        },
        {
            dataField: "d_w_h",
            text: "DWH",
            type: "number",
            hidden: true,
        },
        {
            dataField: "d_w_p",
            text: "DWP",
            type: "number",
            hidden: true,
        },
        {
            dataField: "d_bw_h",
            text: "DELUXE HOURS",
            type: "number",
        },
        {
            dataField: "d_bw_p",
            text: "DBWP",
            type: "number",
            hidden: true,
        },
        {
            dataField: "m_w_h",
            text: "MWH",
            type: "number",
            hidden: true,
        },
        {
            dataField: "m_w_p",
            text: "MWP",
            type: "number",
            hidden: true,
        },
        {
            dataField: "m_bw_h",
            text: "MOVE IN OUT HOURS",
            type: "number",
        },
        {
            dataField: "m_bw_p",
            text: "MBWP",
            type: "number",
            hidden: true,
        },
    ];

    const [pricingdata, setPricingdata] = useState([]);

    function afterSaveCell(oldValue, newValue, rowIndex, columnIndex) { 
        // Get the updated row from pricingdata
        const updatedRow = pricingdata[rowIndex];

        // Safely access the dataField from the column
        const columnField = columns[columnIndex]?.dataField;

        const updatePricingData = "updatePricingData"; // Your endpoint for updating pricing data


        const fullUrl = `${API_URL}${updatePricingData}`;

        const updateData = {
            id: rowIndex.id,
            data: rowIndex
        };

        console.log("Constructed URL:", fullUrl); // Log the full URL for debugging

        // Send the request using fetch with JSON
        fetch(fullUrl , {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(updateData) // Send data as JSON
        })
            .then(response => response.json())
            .then(data => {
                if (data.status === "1") {
                    alert("Data updated successfully!");
                } else {
                    alert("Failed to update data");
                }
            })
            .catch((error) => {
                console.log("Error updating data:", error);
                alert("Error updating data");
            });

        if (!columnField) {
            console.error("Invalid dataField for column at index", columnIndex);
            return;
        }

        // Update the row with the new value
        updatedRow[columnField] = newValue;

        // Update the state with the new data
        const updatedPricingData = [...pricingdata];
        updatedPricingData[rowIndex] = updatedRow;
        setPricingdata(updatedPricingData);


    }




    useEffect(() => {
        getpricingdata()
            .then((res) => {
                if (res.code === 200) {
                    const ApiResponse = res.data;
                    setPricingdata(ApiResponse.data);
                } else {
                    alert("Something went wrong");
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return (
        <div className="dashboard-container d-flex">
            <div className="sidebar-container">
                <Sidebar />
            </div>
            <div className="main-container">
                <div className="main-page-body-content">
                    <div className="main-page-body-content-body">
                        <div className="main-page-body-content-body-container">
                            <div className="pd-26">
                                <div className="table-card">
                                    <div className="card-body table-card-body">
                                        <div className="table-card-header mb-4 d-flex justify-content-between">
                                            <h5 className="card-title">Pricing</h5>
                                            <div className="d-flex justify-content-end">
                                                <div className="export-btn"></div>
                                            </div>
                                        </div>
                                        <div className="table-card-body-inner">
                                            <div className="animated fadeIn">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="card">
                                                            <div className="card-header d-flex table-card-header"></div>
                                                            <div className="card-body">
                                                                <BootstrapTable
                                                                    keyField="id"
                                                                    data={pricingdata} // Use pricingdata here
                                                                    columns={columns}
                                                                    cellEdit={cellEditFactory({
                                                                        mode: "click",
                                                                        blurToSave: true,
                                                                        afterSaveCell,
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Pricing;
