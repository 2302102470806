import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { informasiList } from "./repository";
import pagination from "./pagination";
import SearchBox from "./SearchBox";
import Button from "react-bootstrap/Button";
import "./table.css";
import Delete from "../Delete";
import { FaRegEdit } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { getService } from "../../Api/servicesApi";
import { getservicesdata } from "../../Api/servicesApi";
import { updateservice } from "../../Api/servicesApi";
import { deleteservicesdata } from "../../Api/servicesApi";
import { useEffect } from "react";
import { SITE_URL } from "../../Api/config";
import { RiDeleteBin6Line } from "react-icons/ri";
import { activeservice } from "../../Api/servicesApi";
import { deactivatedservice } from "../../Api/servicesApi";

const Informasi = () => {
  const [showError, setShowError] = useState(false)
  const [show18, setShow18] = useState(false);

  const handleClose18 = () => setShow18(false);
  const handleShow18 = () => setShow18(true);
  const [delcount, setDelcount] = useState(0);

  const [show04, setShow04] = useState(false);

  const handleClose04 = () => setShow04(false);
  const handleShow04 = () => setShow04(true);

  const [selected, setSelected] = useState([]);

  const [serviceimage, setServiceimage] = useState(null);
  const [ServiceList, setServiceList] = useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [serviceAvailability, setServiceAvailability] = useState("");

  const [editservice, setEditservice] = useState({
    id: "",
    image: null,
    description: "",
    title: "",
    service_availability: "",
    service_percentage: "",
    hourly_rate: "",
    base_price: ""
  }); 
  const [serviceImagePreview, setServiceImagePreview] = useState("");

  const [editserviceavailability, setEditserviceavailability] = useState("");

  const handleEditInputChange = (event) => {
    const { name, value } = event.target;
    setEditservice((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
    console.log("event.target", event.target);
  };

  const getServiceList = () => {
    getService(searchText).then((res) => {
      if (res.code === 200) {
        const apiResponse = res.data;
        setServiceList(apiResponse.data.services);
      } else {
        console.log("Service List is--------");
        alert("No data found");
      }
    });
  };


  useEffect(() => {
    getServiceList();
  }, [searchText]);


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setServiceimage(file);
    console.log("file", file);
  };

  useEffect(() => {
    getServiceList();
  }, []);


  const handleActive = (id) => {
    if (window.confirm("Are you sure you wish to enable this item?")) {
      const formData = new FormData();
      formData.append("service_id", id);
      activeservice(formData).then((res) => {
        if (res.code === 200) {
          if (res.data.status === "1") {
            alert("Service is activated");
          } else {
            alert("Service is not activated");
          }
          getServiceList();
        } else {
          alert("No data found");
        }
      });
    }
  };

  const handleDeactive = (id) => {
    if (window.confirm("Are you sure you wish to disable this item?")) {
      const formData = new FormData();
      formData.append("service_id", id);
      deactivatedservice(formData).then((res) => {
        if (res.code === 200) {
          if (res.data.status === "1") {
            alert("Service is deactivated");
          } else {
            alert("Service is not deactivated");
          }
          getServiceList();
        } else {
          alert("No data found");
        }
      });
    }
  };

  const optionFormatter = (cell, row) => {
    return (
      <div className="button-group">
        <Link to={`/serviceprice/${row.id}`}>
          {/* <Link to={`/serviceprice/`}> */}{" "}
          <Button className="Pricing-Active-bttn">Pricing</Button>{" "}
        </Link>
      </div>
    );
  };

  const optionFormatter2 = (cell, row) => {
    return (
      <div className="button-group">
        {row.status === "D" ? (
          <Button
            className="Enable-Active-bttn"
            onClick={(id) => handleActive(row.id, window.event)}
          >
            Enable
          </Button>
        ) : (
          <Button
            className="Enable-Active-bttn"
            onClick={(id) => handleDeactive(row.id, window.event)}
          >
            Disable
          </Button>
        )}
      </div>
    );
  };

  const optionFormatter3 = (cell, row) => {
    return (
      <>
        <div className="button-group">
          <Button
            className="Delete-Active-bttn me-2"
            onClick={() => {
              if (window.confirm("Are you sure you wish to delete this item?"))
                deleteservice(row.id);
            }}
          >
            <RiDeleteBin6Line />
          </Button>
          <Button
            className="Edit-Active-bttn"
            onClick={() => handleserviceedit(row.id)}
          >
            <FaRegEdit />
          </Button>
        </div>
      </>
    );
  };

  const deleteservice = (id) => {
    const formData = new FormData();
    formData.append("service_id", id);
    deleteservicesdata(formData).then((res) => {
      if (res.code === 200) {
        if (res.data.status === "success") {
          alert("Service is deleted");
        } else {
          alert("Service is not deleted");
        }
        getServiceList();
      } else {
        alert("No data found");
      }
    });
  };

  const handleserviceedit = (id) => {
    handleShow18();

    const formData = new FormData();
    formData.append("service_id", id);

    getservicesdata(formData).then((res) => {
      if (res.code === 200) {
        const apiResponse = res.data;
        console.log("apiResponse", apiResponse.data);
        setEditservice(apiResponse.data);
        setServiceImagePreview(apiResponse.data.image);

      } else {
        alert("No data found");
      }
    });
  };

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelected([...selected, row.id]);
    } else {
      setSelected(selected.filter((x) => x !== row.id));
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r.id);
    if (isSelect) {
      setSelected(ids);
    } else {
      setSelected([]);
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "servicename",
      text: "SERVICE NAME",
      sort: true,
    },
    {
      dataField: "service_type",
      text: "SERVICE TYPE",
      sort: true,
      formatter: (cell, row) => {
        if (row.service_type == 0) {
          return "New Client";
        } else if (row.service_type == 1) {
          return "Existing Client";
        } else if (row.service_type == 2) {
          return "Both";
        }
        else if (row.service_type == 3) {
          return "Admin Service"
        }
      }
    },
    {
      dataField: "pricing",
      text: "PRICING",
      isDummy: true,
      formatter: optionFormatter,
      hidden: true,
    },
    {
      dataField: "service_percentage",
      text: "SERVICE PERCENTAGE",
      sort: true,
    },
    {
      dataField: "base_price",
      text: "BASE PRICE",
      sort: true,
    },
    {
      dataField: "hourly_rate",
      text: "HOURLY RATE",
      sort: true,
    },
    {
      text: "STATUS",
      isDummy: true,
      formatter: optionFormatter2,
    },
    {
      text: "ACTION",
      isDummy: true,
      formatter: optionFormatter3,
    },
  ];

  const updateService = () => {
    const formData = new FormData();
    formData.append("service_id", editservice.id);
    formData.append("service_title", editservice.title);
    formData.append("image", serviceimage);
    formData.append("service_description", editservice.description);
    formData.append("service_availability", editserviceavailability);
    formData.append("service_percentage", editservice.service_percentage); 
    formData.append("hourly_rate", editservice.hourly_rate);
    formData.append("base_price", editservice.base_price);

    if (editservice.description == '' ||
      editservice.title == '') {
      setShowError(true)
    } else {
      updateservice(formData)
        .then((res) => {
          if (res.code === 200) {
            if (res.data.status === "success") {
              alert("Service updated successfully");
              handleClose18();
            } else {
              alert("Service not updated");
            }
            getServiceList();
          } else {
            alert("No data found");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }



    // handleClose18();
  };

  return (
    <div>
      {/* Edit Service modal start here */}
      <Modal show={show18} onHide={handleClose18} className="Sidebar-Modal-end">
        <Modal.Header closeButton>
          <Modal.Title>Edit Service</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custum-modal-body">
          <form id="Edit Service">
            <div className="row">
              <div className="col-md-12">
                <div className="logo-content">
                  <div className="add-logo">
                    <div className="image-preview">
                      {serviceImagePreview && (
                        <img
                          src={
                            SITE_URL + "uploads/photo/" + serviceImagePreview
                          }
                          alt="Profile Preview"
                          className="profile-img"
                        />
                      )}
                    </div>
                    <div className="mt-2">
                      <div className="form-group block-upload">
                        <div className="upload-logo">
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input"
                              id="customFile"
                              onChange={handleFileChange}
                              alt="Choose"
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="customFile"
                            >
                              Choose
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form.Group className="mb-3 mt-3" controlId="formBasicEmail">
                  <Form.Label>Service Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={editservice.title || ""}
                    name="title"
                    onChange={handleEditInputChange}
                  />
                  {showError && editservice.title == '' ? <p style={{ color: 'red' }}>field is required</p> : ''}
                  <Form.Control
                    type="hidden"
                    placeholder=""
                    value={editservice.id || ""}
                    name="id"
                    onChange={handleEditInputChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Service Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={editservice.description || ""}
                    name="description"
                    onChange={handleEditInputChange}
                  />
                  {showError && editservice.description == '' ? <p style={{ color: 'red' }}>field is required</p> : ''}
                </Form.Group>
                <Form.Select aria-label="Default select example" onChange={
                  (e) => {
                    setEditserviceavailability(e.target.value)
                  }
                }>
                  <option selected={editservice.service_availability == '0' ? 'selected' : ''} value="0">New Client</option>
                  <option selected={editservice.service_availability == '1' ? 'selected' : ''} value="1">Existing Client</option>
                  <option selected={editservice.service_availability == '2' ? 'selected' : ''} value="2">Both</option>
                  <option selected={editservice.service_availability == '3' ? 'selected' : ''} value="3">Admin Service</option>
                </Form.Select>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Service Percentage</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={editservice.service_percentage || ""}
                    name="service_percentage"
                    onChange={handleEditInputChange}
                  />
                  {showError && editservice.service_percentage == '' ? <p style={{ color: 'red' }}>field is required</p> : ''}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Hourly Rate</Form.Label>
                  <Form.Control
                    type="number" 
                    value={editservice.hourly_rate || ""}
                    name="hourly_rate"
                    onChange={handleEditInputChange}
                  /> 
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Base Price</Form.Label>
                  <Form.Control
                    type="number" 
                    value={editservice.base_price || ""}
                    name="base_price"
                    onChange={handleEditInputChange}
                  /> 
                </Form.Group>
              </div>
            </div>

          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className="Active-bttn btn" onClick={updateService}>
            {" "}
            Update{" "}
          </button>
          <button className="Cancel-bttn btn" onClick={handleClose18}>
            {" "}
            Cancel{" "}
          </button>
        </Modal.Footer>
      </Modal>
      <div className="animated fadeIn">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header d-flex table-card-header">
                <Form.Group>
                  <div className="d-flex">
                    <Form.Control
                      type="text"
                      placeholder="Keywords..."
                      onChange={(e) => {
                        setSearchText(e.target.value);
                      }}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="card-body">
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  data={ServiceList}
                  columns={columns}
                  // selectRow={selectRow}
                  pagination={pagination}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Informasi;
