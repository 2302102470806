import React, { useEffect, useState, useRef } from "react";
import Sidebar from "../Components/Sidebar/Sidebar";
import Form from "react-bootstrap/Form";
import "./TechnicianAvailability.css";
import CanvasJSReact from '@canvasjs/react-charts';
import { getreport } from "../Api/technician";
import moment from "moment";
import { Roller } from "react-awesome-spinners";
import { getactivestaff } from "../Api/bookingApi";
import { getZone } from "../Api/bookingApi";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

function TechnicianAvailability() {

  const chartRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [filtereddata, setFiltereddata] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [bookingzonelist, setBookingzonelist] = useState([]);
  const [changestaffzone, setchangesstaffzone] = useState('');
  const [changestaff, setchangesstaff] = useState('');

  const toggleDataSeries = (e) => {
    if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
      e.dataSeries.visible = false;
    } else {
      e.dataSeries.visible = true;
    }
    chartRef.current.render();
  };

  const options = {
    animationEnabled: true,
    exportEnabled: true,
    title: {
      text: "Technician Availability Report - " + moment(new Date()).format('DD-MM-YYYY'),
      fontFamily: "verdana"
    },
    axisX: {
      includeZero: true,
    },
    toolTip: {
      shared: true,
      reversed: true
    },
    legend: {
      verticalAlign: "center",
      horizontalAlign: "right",
      reversed: true,
      cursor: "pointer",
      itemclick: toggleDataSeries
    },
    data: [
      {
        type: "stackedColumn",
        name: "Total Availability",
        showInLegend: true,
        yValueFormatString: "#,###",
        dataPoints: filtereddata.map(item => ({ label: item.fullname.trim().split(' ')[0], y: item.total_avalibility })),
      },
      {
        type: "stackedColumn",
        name: "Booked Hours",
        showInLegend: true,
        yValueFormatString: "#,###",
        dataPoints: filtereddata.map(item => ({ label: item.fullname.trim().split(' ')[0], y: item.bookingEventDuration })),
      },
      {
        type: "stackedColumn",
        name: "Hour Remaining",
        color: "#75b317",
        showInLegend: true,
        yValueFormatString: "#,###",
        dataPoints: filtereddata.map(item => ({ label: item.fullname.trim().split(' ')[0], y: item.bookinghourremaning })),
      }
    ]
  };

  const getAvailabilityData = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("start_date", moment(new Date()).format('YYYY-MM-DD'));
    formData.append("end_date", moment(new Date()).add(1, 'days').format('YYYY-MM-DD'));
    formData.append("zoneid", changestaffzone);
    getreport(formData).then((res) => {
      if (res.code === 200) {
        const ApiResponse = res.data;
        setFiltereddata(ApiResponse.data);
        setOriginalData(ApiResponse.data);
      } else {
        // alert("Something went wrong");
        console.log("Something went wrong");
      }
    })
      .catch((error) => {
        console.log(error);
      }).finally(() => {
        setLoading(false);
      });
  };

  const getstaff = () => {
    getactivestaff().then((res) => {
      if (res.code === 200) {
        const apiResponse = res.data;
        setStaffList(apiResponse.data);
      } else {
        alert("Something went wrong");
      }
    });
  };

  const getstafflist = () => {

    getstafflist().then((res) => {
      if (res.code === 200) {
        const ApiResponse = res.data;
        console.log("ApiResponse", ApiResponse.data);
        setStaffList(ApiResponse.data);
      } else {
        alert("Something went wrong");
      }
    })
      .catch((error) => {
        console.log(error);
      });
  };


  const getallzone = () => {
    getZone().then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          setBookingzonelist(response.data.data);
        } else {
          console.log("response: error");
        }
      } else {
        console.log("response: error");
      }
    }).catch((error) => {
      console.log("response: error");
    });
  }


  useEffect(() => {
    getAvailabilityData();
    getstaff();
    getallzone();
  }, []);

  useEffect(() => {
    chartRef.current.render();
  }, []);


  useEffect(() => {
    setLoading(true);
    const formData = new FormData();
    formData.append("start_date", moment(new Date()).format('YYYY-MM-DD'));
    formData.append("end_date", moment(new Date()).add(1, 'days').format('YYYY-MM-DD'));
    formData.append("zoneid", changestaffzone);
    getreport(formData).then((res) => {
      if (res.code === 200) {
        const ApiResponse = res.data;
        setFiltereddata(ApiResponse.data);
        setOriginalData(ApiResponse.data);
      } else {
        // alert("Something went wrong");
        console.log("Something went wrong");
      }
    })
      .catch((error) => {
        console.log(error);
      }).finally(() => {
        setLoading(false);
      });
  }, [changestaffzone]);




  useEffect(() => {
    if (changestaff !== '' && changestaff != 0) {
      const filtered = originalData.filter(item => item.staff_id === changestaff);
      setFiltereddata(filtered);
    } else {
      setFiltereddata(originalData);
    }
  }, [changestaff, originalData]);


  //fiter filtereddata by staffList 
  // const filterByStaff = () => {
  //   if (changestaffzone === 0) {
  //     getAvailabilityData();
  //   } else {
  //     const filtered = filtereddata.filter((item) => item.zone_id === changestaffzone);
  //     setFiltereddata(filtered);
  //   }
  // }



  return (
    <div>
      {
        loading ? (
          <div className="loading">
            <Roller />
          </div>
        ) : (
          <>
            <div className="dashboard-container d-flex">
              <div className="sidebar-container">
                <Sidebar />
              </div>
              <div className="main-container">
                <div className="main-page-body-content">
                  <div className="main-page-body-content-body">
                    <div className="main-page-body-content-body-container">
                      <div className="row">
                        <div className="select-box-list2">
                          <div className="select-box-list-item">
                            <Form.Select aria-label="Default select example" value={changestaffzone} onChange={
                              (e) => {
                                setchangesstaffzone(e.target.value)
                              }
                            }>
                              <option value="0">All Zone</option>
                              {bookingzonelist && bookingzonelist.map((zone) => (
                                <option value={zone.id} >{zone.zone_name}</option>
                              ))}

                            </Form.Select>
                          </div>
                          <div className="select-box-list-item">
                            <Form.Group controlId="formBasicEmail">
                              <Form.Select aria-label="Default select example" onChange={
                                (e) => {
                                  setchangesstaff(e.target.value)
                                }
                              }>
                                <option value={0}>Select Technician</option>
                                {staffList && staffList.map((staff) => (
                                  <option value={staff.value} >{staff.label}</option>
                                ))
                                }
                              </Form.Select>
                            </Form.Group>
                          </div>
                        </div>
                      </div>

                      <div className="pd-26">
                        <div className="animated fadeIn">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="card">
                                <div className="card-header d-flex table-card-header">
                                </div>
                                <div className="card-body">
                                  <CanvasJSChart options={options} onRef={ref => (chartRef.current = ref)} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }
    </div >
  );
}
export default TechnicianAvailability;
