import React, { useState, useEffect } from "react";
import { useRef } from 'react';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from 'react-bootstrap/Spinner';
import { Roller } from "react-awesome-spinners";
import Form from "react-bootstrap/Form";
import "./Appointment.css";
import BootstrapTable from 'react-bootstrap-table-next';
import pagination from './pagination';
import { TbHotelService, TbCalendar, TbClock } from "react-icons/tb";
import { FaRegEdit, FaCheck, FaThumbsDown, FaRegTimesCircle, FaCheckCircle, FaTimesCircle, FaMoneyBillAlt, FaToggleOn } from "react-icons/fa";
import { MdAdd, MdClose } from "react-icons/md";
import { RiDeleteBin6Line, RiCloseCircleFill } from "react-icons/ri";
import { BsStopwatch } from "react-icons/bs";
import { userslist } from "../Api/bookingApi";
import 'react-notifications/lib/notifications.css';
import Select from "react-select";
import { getactivestaff } from "../Api/bookingApi";
import { getbookingservices } from "../Api/bookingApi";
import { getEditbookingData } from "../Api/bookingApi";
import { getStaff } from "../Api/bookingApi";
import { updateBookingData } from "../Api/bookingApi";
import moment from "moment";
import { confirmbookingstatus } from "../Api/bookingApi";
import { rejectbookingstatus } from "../Api/bookingApi";
import { deletebookingApi } from "../Api/bookingApi";
import { reccuringdeletebooking } from "../Api/bookingApi";
import { getrescheduledata } from "../Api/bookingApi";
import { getDates } from "../Api/bookingApi";
import { getZone } from "../Api/bookingApi";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { BsPlusLg } from "react-icons/bs";
import { usePaymentInputs } from 'react-payment-inputs';

import { unconfirmbookingstatus } from "../Api/bookingApi";
import { complatebookingstatus } from "../Api/bookingApi";
import { uncomplatebookingstatus } from "../Api/bookingApi";
import { activebookingstatus } from "../Api/bookingApi";
import { createStaxIdApi } from "../Api/bookingApi";
import { createinvoiceApi } from "../Api/bookingApi";

import { getsubserviceaddonsApi } from "../Api/bookingApi";
import { getinvoiceApi } from "../Api/bookingApi";
import { createPaymentMethod } from "../Api/bookingApi";
import { getpaymentMethod } from "../Api/bookingApi";
import { payinvoiceApi } from "../Api/bookingApi";
import { checkemailApi } from "../Api/bookingApi";
import { getpreferredstaffApi } from "../Api/bookingApi";
import { getbookingservicesApi, updaterecurringbookingAPi, findzonebyzipApi, getproductlistdataApi, updatecrmdataApi } from "../Api/bookingApi";
import ChildComponent from '../Components/EditUser/Informasi';
import { getBookingById } from "../Api/bookingApi";

import InputMask from 'react-input-mask';

// import {date_time}  from "../helper/timezone";

import { Link } from "react-router-dom";



function Appointment({ order_id, onCloseidChangebooking, onCloseidChangeoldbookingid, onclosepopup }) {


  const [bookingDetails, setBookingDetails] = useState([]);

  const [showBookingDetails, setShowBookingDeatils] = useState(false);

  const handleClose2 = () => setShowBookingDeatils(false);
  const handleShow2 = () => setShowBookingDeatils(true);



  const getBookingDetails = (id) => {
    setLoading(true);
    const order_id = id;
    const formData = new FormData();
    formData.append("order_id", order_id);
    getBookingById(formData).then((res) => {
      if (res.code === 200) {
        const apiResponse = res.data;
        setBookingDetails(apiResponse.data);
      }
    }).catch((err) => {
      console.log(err);
      setLoading(false);
    }).finally(() => {
      setLoading(false);
      setShowBookingDeatils(true);
      onclosepopup(false);
    })

  }

  useEffect(() => {
    if (order_id) {
      getBookingDetails(order_id);
    }
  }, [order_id])



  const dateData = moment(new Date()).format("YYYY-MM-DD");

  const [bookingDate, setBookingDate] = useState(dateData);
  const [recurringtime, setRecurringtime] = useState(dateData);
  const [editstart, setEditstart] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setBookingDate(dateData);
    setRecurringtime(moment(dateData).format("HH:mm:ss"));
  }, [dateData]);

  const [users, setUsers] = useState([]);
  const [activeStaff, setActiveStaff] = useState([]);
  const [service, setService] = useState([]);
  const [subService, setSubService] = useState([]);
  const [ChooseService, setChooseService] = useState([]);

  const [ChooseStaff, setChooseStaff] = useState([]);
  const [editbooking, setEditbooking] = useState([]);
  const [staffList, setStaffList] = useState(null);
  const [assignStaff, setAssignStaff] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [userType, setUserType] = useState(0);



  const getStaffList = () => {
    getactivestaff().then((res) => {
      if (res.code === 200) {
        const apiResponse = res.data;
        setStaffList(apiResponse.data);
      }
    })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getStaffList();
  }, []);




  const [searchkeyword, setSearchkeyword] = useState('');
  const [pageno, setPageno] = useState(1);

  const userdata = (type) => {


    userslist(searchkeyword, type == "search" ? 1 : pageno)
      .then((res) => {
        if (res.code === 200) {
          const apiResponse = res.data;
          if (apiResponse.data.length > 0) {

            if (type === "scroll") {
              setUsers([...users, ...apiResponse.data]);
              setPageno(pageno + 1);
            }
            else {
              setUsers(apiResponse.data);
            }

          }
          else {
            if (type === "search") {
              setUsers([]);
            }
          }
        } else {
          alert("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });

  };

  useEffect(() => {
    userdata();
    getstaff();
    productlistdata()
  }, []);

  useEffect(() => {
    getservices(userType);
  }, [userType]);

  useEffect(() => {
  }, [selectedOptions]);

  useEffect(() => {
    if (searchkeyword !== '') {
      setPageno(1);
      userdata("search");
    }
  }, [searchkeyword]);



  // create a function to convert hh:mm in minutes 
  const convertToMinutes = (time) => {
    const [hours, minutes] = time.split(':');
    return parseInt(hours) * 60 + parseInt(minutes);
  }



  useEffect(() => {
    // convert hh:mm in minutes 
  }, [ChooseStaff]);


  const [show3, setShow3] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);



  const [show4, setShow4] = useState(false);

  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show6, setShow6] = useState(false);

  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const [showCustomer, setShowCustomer] = useState(false);

  const handleCloseCustomer = () => setShowCustomer(false);
  const handleShowCustomer = () => setShowCustomer(true);

  const [show5, setShow5] = useState(false);

  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [addons, setAddons] = React.useState([]);
  const [servicepercentage, setServicepercentage] = React.useState(0);

  const getstaff = () => {
    getactivestaff().then((res) => {
      if (res.code === 200) {
        const apiResponse = res.data;
        setActiveStaff(apiResponse.data);
      } else {
        alert("Something went wrong");
      }
    });
  };


  const getservices = (userType) => {
    getbookingservices(userType).then((res) => {
      if (res.code === 200) {
        const apiResponse = res.data;
        setService(apiResponse.data);
      } else {
        alert("Something went wrong");
      }
    });
  };



  const [basewithextra, setBasewithextra] = useState(0);

  const [eventcount, setEventcount] = useState(0);




  const getStatusofbookings = (bookingstatus = "") => {
    if (bookingstatus === "A") {
      return "Unconfirmed";
    } else if (bookingstatus === "C") {
      return "Confirm";
    } else if (bookingstatus === "R") {
      return "Reject";
    } else if (bookingstatus === "CC") {
      return "Cancel by Client";
    } else if (bookingstatus === "CS") {
      return "Cancel by service provider";
    } else if (bookingstatus === "CO") {
      return "Completed";
    } else if (bookingstatus === "MN") {
      return "Mark as no show";
    } else if (bookingstatus === "UC") {
      return "Confirmed by Client";
    } else {
      return "";
    }
  };

  const getDateFormate = (appointmentstarttime = "") => {
    if (appointmentstarttime === null) {
      return "";
    } else {
      const date = appointmentstarttime.split(" ");
      const dateformate = date[0].split("-");
      const month = new Date(
        dateformate[0],
        dateformate[1] - 1,
        dateformate[2]
      );
      const monthname = month.toLocaleString("default", { month: "short" });
      return `${monthname}-${dateformate[2]}-${dateformate[0]}`;
    }
  };

  const getassigendstaff = (staff = "") => {
    if (staff === null) {
      return "";
    } else {
      const staffname = staff.replace(/(<([^>]+)>)/gi, "");
      return staffname;
    }
  };

  const getNotes = (bookingnotes = "") => {
    if (bookingnotes === null) {
      return "";
    } else {
      const notes = bookingnotes.replace(/(?:\r\n|\r|\n)/g, "<br />");
      const notes1 = notes.replace(/(<([^>]+)>)/gi, "");
      return notes1;
    }
  };

  const [edituser, setEdituser] = useState({
    id: "",
    image: null,
    user_email: "",
    user_pwd: "",
    first_name: "",
    last_name: "",
    phone: "",
    zip: "",
    address: "",
    city: "",
    state: "",
    notes: "",
    preferences: "",
    family_info: "",
    parking_access: "",
    preferrend_technician: "",
    special_instructions: "",
    technician_notes: "",
    appointment_note_by_staff: "",
    bathrooms: "",
    bedrooms: "",
    billed_amount: "",
    key_number: "",
    billed_hours: "",
    work_phone: "",
    message_phone: "",
    special_instructions_admin: "",
    payment_method_id: "",
    shipping_address: "",
    shipping_zip: "",
    shipping_city: "",
    shipping_state: "",
    customer_stage: "",
    zone: "",
    client_owner: "",
    email_status: "E",
    sms_status: "E",
    stax_payment_method: "",
    customer_id: "",
    estimated_duration: "",
    estimated_price: "",
  });


  const [preferredStaff, setPreferredStaff] = useState([]);

  const getpreferredstaff = () => {
    getpreferredstaffApi().then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          setPreferredStaff(response.data.data);
        } else {
          alert("data not found");
        }
      } else {
        alert("Something went wrong");
      }

    }
    ).catch((error) => {
      console.log(error);
    }
    );
  }

  useEffect(() => {
    getpreferredstaff();
  }, [edituser]);



  function convertTimeTo24Hour(time) {
    // Split the time into hours, minutes, and AM/PM components
    const [timeString, modifier] = time.split(' ');
    const [hours, minutes] = timeString.split(':');

    // Convert hours to 24-hour format
    let hours24 = parseInt(hours, 10);
    if ((modifier == 'pm' || modifier == 'PM') && hours24 !== 12) {
      hours24 += 12;
    } else if ((modifier == 'am' || modifier == 'AM') && hours24 === 12) {
      hours24 = 0;
    }

    // Pad the hours and minutes with leading zeros if necessary
    const formattedHours = String(hours24).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');

    // Return the time in 24-hour format
    return `${formattedHours}:${formattedMinutes}`;
  }


  const [EditbookingStartTime, setEditbookingStartTime] = useState(null);
  const [EditbookingEndTime, setEditbookingEndTime] = useState(null);

  const [EditbookingStartDate, setEditbookingStartDate] = useState(null);
  const [EditbookingEndDate, setEditbookingEndDate] = useState(null);
  const [EditSubServices, setEditSubServices] = useState([]);
  const [editaddons, setEditAddons] = useState([]);
  const [updatestaff, setUpdatestaff] = useState([]);

  const getEditbooking = () => {


    setLoading(true);
    const formData = new FormData();
    formData.append("order_id", bookingDetails.order_id);
    formData.append("client_id", bookingDetails.client_id);

    getEditbookingData(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          const apiResponse = response.data;
          setEditbooking(apiResponse.data);
          setEditbookingstate(apiResponse.data.appointment_state)
          setUpdatestaff(apiResponse.data.staff_ids);
          const staff_ids = apiResponse.data.staff_ids;
          const staff_ids_array = staff_ids.split(",");
          const staff_ids_index = [];
          staff_ids_array.forEach((staff_id) => {
            const index = staffList.findIndex((staff) => staff.value === staff_id);
            staff_ids_index.push(index);
          });
          const selectedStaff = [];
          staff_ids_index.forEach((index) => {
            selectedStaff.push(staffList[index]);
          });
          setSelectedOptions(selectedStaff);

          const start_time = apiResponse.data.start_time;
          // const start_time = getbookingStartTime(apiResponse.data.start_time);

          setEditbookingStartTime(convertTimeTo24Hour(start_time));

          // setEditbookingStartTime(start_time);

          const end_time = getbookingStartTime(apiResponse.data.end_time);
          setEditbookingEndTime(end_time);

          const start_d_time = getbookingStartDate(apiResponse.data.booking_date_time);
          setEditbookingStartDate(start_d_time);

          const end_d_time = getbookingStartDate(apiResponse.data.booking_date_time);
          setEditbookingEndDate(end_d_time);

          setEditSubServices(apiResponse.data.subservices);
          setEditAddons(apiResponse.data.addons);
          const staff = apiResponse.data.staff_ids;
          setAssignStaff(selectedStaff);
          setEditbookingdiscount(apiResponse.data.booking_discount);

        } else {
          alert("Something went wrong");
        }
      } else {
        alert("Something went wrong");
      }
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      setLoading(false);
      handleShow3();
    })
  }



  const getbookingStartDate = (bookingdatetime = "") => {
    const bookingDateTime = bookingdatetime.split(" ");
    const bookingDate = bookingDateTime[0];
    return bookingDate;
  }

  const getbookingStartTime = (start_time = "") => {
    var start_time = start_time.split(" ");
    var start_time1 = start_time[0].split(":");
    var starttime = start_time1[0] + ":" + start_time1[1];
    return starttime;
  }

  const handleEditInputChange3 = (event) => {
    const { name, value } = event.target;
    setEditbooking((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };


  const [editbookingstate, setEditbookingstate] = useState(" ");




  const [totalAmount, setTotalAmount] = useState(0);
  const [totalDuration, setTotalDuration] = useState(0);
  const [baseTime, setBaseTime] = useState(0);

  const [expectedtotalAmount, setExpectedTotalAmount] = useState(0);


  const [editbookingestimatedPrice, setEditbookingestimatedPrice] = useState(0);
  const [editbookingestimatedDuration, setEditbookingestimatedDuration] = useState("00:00");

  const [editbookingdiscount, setEditbookingdiscount] = useState(0);
  const [editbookingchangesamount, setEditbookingChangesAmount] = useState('');


  const handlechageeditamount = (value) => {
    if (value != "") {
      var value = parseInt(value);
      setEditbookingestimatedPrice(value);
      // const totalAmountvar1 = (10 * value) / 11;
      const service_percentage = parseInt(editbooking.service_percentage);
      const totalAmountvar1 = ((100 - service_percentage) * value) / 100;
      setEditTotalAmount(parseInt(totalAmountvar1));
    }
    else {
      setEditTotalAmount(0);
      setEditbookingestimatedPrice(0);
    }
  }


  const handlechageeditdiscountamount = (value) => {
    if (value !== "") {
      var parsedValue = parseInt(value);
      setEditbookingdiscount(parsedValue);
      const discountprice = parseInt(editbookingestimatedPrice) - parsedValue;
      setEditbookingChangesAmount(parseInt(discountprice));

      var Servicepercentage = parseInt(editbooking.service_percentage);
      let totalAmountvar1;
      if (Servicepercentage > 0) {
        totalAmountvar1 = ((100 - Servicepercentage) * editbookingchangesamount) / 100;
      } else {
        totalAmountvar1 = parseInt(editbooking.billed_amount) - parsedValue;
      }

      setEditTotalAmount(parseInt(totalAmountvar1));
    } else {
      setEditTotalAmount(0);
      setEditbookingestimatedPrice(0);
    }
  }



  const handlechageedittime = (event) => {
    const inputValue = event.target.value;
    const [hours, minutes] = inputValue.split(':');
    const validMinutes = Math.min(Math.max(parseInt(minutes || 0, 10), 0), 59);
    const formattedValue = `${hours || '00'}:${validMinutes.toString().padStart(2, '0')}`;
    let totalDurationvar = convertToMinutes(formattedValue)
    setEditbookingestimatedDuration(totalDurationvar);
    //setExpedtedendtime(totalDurationvar)


    const service_percentage = parseInt(editbooking.service_percentage);
    //const totalDurationvarwithextra = (totalDurationvar * 11) / servicepercentage;
    const totalDurationvarwithextra = ((100 - service_percentage) * totalDurationvar) / 100;


    // setEditBaseTime(parseInt(totalDurationvarwithextra / ChooseStaff.length));
    setEditBaseTime(parseInt(totalDurationvarwithextra));

  };



  useEffect(() => {
    getTimeData();
  }, [totalDuration]);


  const getTimeData = () => {
    setBaseTime(totalDuration);

    const expectedtime = basewithextra;
    setBasewithextra(expectedtime);

  }

  useEffect(() => {
    calculatePrice();
  }, [subService, addons, ChooseService]);

  useEffect(() => {
    setUpdatedendtime(parseInt(basewithextra / ChooseStaff.length));
  }, [ChooseStaff]);



  const [edittotalAmount, setEditTotalAmount] = useState(0);
  const [edittotalDuration, setEditTotalDuration] = useState(0);
  const [editbaseTime, setEditBaseTime] = useState(0);
  const [updatedendtime, setUpdatedendtime] = useState();
  const [expedtedendtime, setExpedtedendtime] = useState();

  useEffect(() => {
    getEditTimeData();
  }, [edittotalDuration]);

  const getEditTimeData = () => {
    // const start_time = edittotalDuration;
    // // var hours = Math.floor(start_time / 60);
    // // var minutes = start_time % 60;

    // // if (hours < 10) {
    // //   hours = "0" + hours;
    // // }
    // // else {
    // //   hours = hours;
    // // }
    // // if (minutes < 10) {
    // //   minutes = "0" + minutes;
    // // }
    // // else {
    // //   minutes = minutes;
    // // }

    // // var time = hours + ":" + minutes;
    setEditBaseTime(edittotalDuration);
  }


  useEffect(() => {
    // if (editstart) {

    const hours = parseInt(editbooking.hours) * 60;
    const mints = parseInt(editbooking.mints);
    const totalDuration = hours + mints;
    if (editbooking.estimated_price == null || editbooking.estimated_price == "") {
      setEditbookingestimatedPrice(editbooking.billed_amount);

      var Servicepercentage = parseInt(editbooking.service_percentage);
      const percentage = (editbooking.billed_amount * Servicepercentage) / 100;
      const totalAmountvar1 = totalDuration + percentage;
      setEditTotalAmount(totalAmountvar1);

    } else {
      const priceRange = editbooking.estimated_price;
      // const [minPriceStr, maxPriceStr] = priceRange.split(' to ');
      const [minPriceStr, maxPriceStr] = priceRange.includes(' to ') ? priceRange.split(' to ') : priceRange.split(' - ');

      // Remove the dollar sign and convert the strings to numbers
      const minPrice = parseFloat(minPriceStr.replace('$', ''));
      const maxPrice = parseFloat(maxPriceStr.replace('$', ''));

      setEditTotalAmount(minPrice)
      setEditbookingestimatedPrice(maxPrice);
    }

    if (editbooking.estimated_duration == null || editbooking.estimated_duration == "") {
      setEditbookingestimatedDuration(editbooking.order_duration);

      var Servicepercentage = parseInt(editbooking.service_percentage);
      const percentage = (totalDuration * Servicepercentage) / 100;
      const totalAmountvar1 = totalDuration + percentage;
      setEditBaseTime(totalAmountvar1);

    } else {
      const estimated_duration = editbooking.estimated_duration;


      // const [startTime, endTime] = estimated_duration.split(' to ');
      const [startTime, endTime] = estimated_duration.includes(' to ') ? estimated_duration.split(' to ') : estimated_duration.split(' - ');

      // Convert start and end times to minutes
      const [startHours, startMinutes] = startTime.split(':');
      const [endHours, endMinutes] = endTime.split(':');
      const startInMinutes = parseInt(startHours) * 60 + parseInt(startMinutes);
      const endInMinutes = parseInt(endHours) * 60 + parseInt(endMinutes);

      setEditbookingestimatedDuration(endInMinutes);

      setEditBaseTime(startInMinutes);

    }


    // setEditTotalDuration(editbaseTime);


    // }
  }, [editbooking.hours, editbooking.mints, editbooking.billed_amount, editbooking.estimated_duration]);


  useEffect(() => {
    const staffids = editbooking.staff_ids;

    if (Array.isArray(staffids)) {
      const staff_length = staffids.length > 0 ? staffids.length : 1;
      setUpdatestaff(staffids);
      setExpedtedendtime(parseInt(editbookingestimatedDuration / staff_length));
    } else {
      console.error("editbooking.staff_ids is not an array or is undefined.");
    }
  }, [editbooking.staff_ids, editbookingestimatedDuration]);


  useEffect(() => {
    if (editstart) {
      calculateEditPriceforbooking();
    }
  }, [EditSubServices, editaddons]);

  useEffect(() => {
    const staff_length = assignStaff.length > 0 ? assignStaff.length : 1;
    setExpedtedendtime(parseInt(editbookingestimatedDuration / staff_length));
  }, [assignStaff, editbookingestimatedDuration]);


  function changeeditaddons(value, id) {

    if (value === "increment") {
      const updatedArray = editaddons.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            addons_service_qty: parseInt(item.addons_service_qty) + 1,
          };
        }

        return item;
      });

      setEditAddons(updatedArray);
    } else if (value === "decrement") {
      const updatedArray = editaddons.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            addons_service_qty: parseInt(item.addons_service_qty) - 1,
          };
        }

        return item;
      });
      setEditAddons(updatedArray);
    }
  }

  function changeCounter3(value, id) {
    if (value === "increment") {
      const updatedArray = EditSubServices.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            quantity: parseInt(item.quantity) + 1,
          };
        }

        return item;
      });
      setEditSubServices(updatedArray);
    } else if (value === "decrement") {
      const updatedArray = EditSubServices.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            quantity: parseInt(item.quantity) - 1,
          };
        }

        return item;
      });
      setEditSubServices(updatedArray);
    }
  }

  const calculateEditPriceforbooking = () => {
    var totalAmountvar = 0;
    var totalDurationvar = 0;
    EditSubServices.map((item) => {
      const basePrice = parseInt(item.base_price);
      const baseCount = 1;
      const baseDuration = parseInt(item.base_duration);
      const extraCount = parseInt(item.quantity) - baseCount;
      const extraPrice = parseInt(item.base_price_extra_unit);
      const extraDuration = parseInt(item.extra_duration_per_unit_in_minute);
      const baseAmount = basePrice * baseCount;
      const extraAmount = extraPrice * extraCount;
      totalAmountvar = totalAmountvar + baseAmount + extraAmount;
      totalDurationvar = totalDurationvar + baseDuration + extraCount * extraDuration;
    });
    editaddons.map((item) => {
      if (item.addons_service_qty == 0 || item.addons_service_qty == null) {

      }
      else {

        const basePrice = parseInt(item.base_price);
        const baseCount = 1;
        const baseDuration = parseInt(item.base_duration);;
        const extraCount = parseInt(item.addons_service_qty) - baseCount;
        const extraPrice = parseInt(item.price_for_per_extra_unit);
        const extraDuration = parseInt(item.extra_minute_per_unit);
        const baseAmount = basePrice * baseCount;
        const extraAmount = extraPrice * extraCount;
        totalAmountvar = totalAmountvar + baseAmount + extraAmount;
        totalDurationvar = totalDurationvar + baseDuration + extraCount * extraDuration;

      }
    });
    setEditTotalAmount(totalAmountvar);
    const staff_length = assignStaff.length > 0 ? assignStaff.length : 1;
    // setEditTotalDuration(parseInt(totalDurationvar / staff_length));

    setEditTotalDuration(parseInt(totalDurationvar));


    //get percentage from totalAmount
    var Servicepercentage = parseInt(editbooking.service_percentage);
    const percentage = (totalAmountvar * Servicepercentage) / 100;
    const totalAmountvar1 = totalAmountvar + percentage;
    setEditbookingestimatedPrice(totalAmountvar1);

    if (editbooking.booking_discount > 0) {
      const discountvalue = totalAmountvar1 - editbooking.booking_discount;
      setEditbookingChangesAmount(discountvalue)
    } else {
      setEditbookingChangesAmount(totalAmountvar1)
    }


    //get percentage from totalDurationvar
    const extrarange = (totalDurationvar * Servicepercentage) / 100;
    const totalDurationvarwithextra = totalDurationvar + extrarange;
    // setEditbookingestimatedDuration(parseInt(totalDurationvarwithextra / staff_length));
    setEditbookingestimatedDuration(parseInt(totalDurationvarwithextra));
    setExpedtedendtime(parseInt(totalDurationvarwithextra / staff_length))
  }

  const UpdateBookigData = () => {
    if (EditbookingStartTime < "07:00" || EditbookingStartTime > "21:00") {
      NotificationManager.error("⚠ Please select time between 07:00 AM to 08:00 PM");
      return;
    }

    const formData = new FormData();
    formData.append("order_id", bookingDetails.order_id);
    formData.append("client_id", bookingDetails.client_id);
    formData.append("start_date", EditbookingStartDate);
    formData.append("start_d_time", EditbookingStartTime);
    formData.append("service_amount", Math.round(editbookingestimatedPrice));
    formData.append("duration", editbookingestimatedDuration);
    formData.append("appointment_note_by_staff", editbooking.appointment_note);
    formData.append("appointment_zip", editbooking.appointment_zip);
    formData.append("appointment_address", editbooking.client_address);
    formData.append("appointment_city", editbooking.appointment_city);
    formData.append("appointment_state", editbookingstate);
    formData.append("client_name", editbooking.client_name);

    formData.append("booking_discount", editbookingdiscount);

    formData.append("estimated_price", "$" + edittotalAmount + " to " + "$" + Math.round(editbookingestimatedPrice));
    formData.append("estimated_duration", convertToHHMM(editbaseTime) + " to " + convertToHHMM(editbookingestimatedDuration));



    const subServices = [];
    for (let i = 0; i < EditSubServices.length; i++) {
      // subServices.push(EditSubServices[i].quantity);
      subServices.push({ id: EditSubServices[i].booking_id, quantity: EditSubServices[i].quantity })
    }
    formData.append("method_unit_selected_values", JSON.stringify(subServices));

    const staffIds = [];

    if (assignStaff != null && assignStaff != "" && assignStaff != undefined && assignStaff.length > 0) {

      for (let i = 0; i < assignStaff.length; i++) {
        staffIds.push(assignStaff[i].value);
      }
      formData.append("staff_ids", staffIds);

    }
    else {
      // const staffIds = [];

      // updatestaff.map((item) => {
      //   staffIds.push(item);
      // });

      formData.append("staff_ids", 79);
    }




    const addons = [];
    for (let i = 0; i < editaddons.length; i++) {
      addons.push({ id: editaddons[i].id, quantity: editaddons[i].addons_service_qty, type: editaddons[i].new });
    }
    formData.append("addons_service_qty", JSON.stringify(addons));

    updateBookingData(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          // alert("Booking updated successfully");
          handleClose3();
          handleClose2();
          // window.location.reload();
          setEventcount(eventcount + 1);
        } else {
          alert("Something went wrong");
        }
      } else {
        alert("Something went wrong");
      }
    }).catch((error) => {
      //console.log(error);
    }).finally(() => {
      setStartDate(EditbookingStartDate);
      onCloseidChangebooking(0);
      onCloseidChangeoldbookingid(bookingDetails.order_id);
    });
  }

  const [apicall, setApicall] = useState(false);

  const updaterecurringbooking = () => {

    if (EditbookingStartTime < "07:00" || EditbookingStartTime > "21:00") {
      NotificationManager.error("⚠ Please select time between 07:00 AM to 08:00 PM");
      return;
    }

    setApicall(true);
    const formData = new FormData();
    formData.append("recurring_id", bookingDetails.recurring_id);
    formData.append("client_id", bookingDetails.client_id);
    formData.append("start_date", EditbookingStartDate);
    formData.append("start_d_time", EditbookingStartTime);
    formData.append("service_amount", Math.round(editbookingestimatedPrice));
    formData.append("duration", editbookingestimatedDuration);
    formData.append("appointment_note_by_staff", editbooking.appointment_note);
    formData.append("appointment_zip", editbooking.appointment_zip);
    formData.append("appointment_address", editbooking.client_address);
    formData.append("appointment_city", editbooking.appointment_city);
    formData.append("appointment_state", editbookingstate);
    formData.append("client_name", editbooking.client_name);


    formData.append("estimated_price", "$" + edittotalAmount + " to " + "$" + Math.round(editbookingestimatedPrice));
    formData.append("estimated_duration", convertToHHMM(editbaseTime) + " to " + convertToHHMM(editbookingestimatedDuration));



    const subServices = [];
    for (let i = 0; i < EditSubServices.length; i++) {
      // subServices.push(EditSubServices[i].quantity);
      subServices.push({ id: EditSubServices[i].booking_id, method_id: EditSubServices[i].id, quantity: EditSubServices[i].quantity })
    }
    formData.append("method_unit_selected_values", JSON.stringify(subServices));

    const staffIds = [];

    if (assignStaff != null && assignStaff != "" && assignStaff != undefined && assignStaff.length > 0) {

      for (let i = 0; i < assignStaff.length; i++) {
        staffIds.push(assignStaff[i].value);
      }
      formData.append("staff_ids", staffIds);

    }
    else {
      // const staffIds = [];

      // updatestaff.map((item) => {
      //   staffIds.push(item);
      // });

      formData.append("staff_ids", 79);
    }




    const addons = [];
    for (let i = 0; i < editaddons.length; i++) {
      addons.push({ id: editaddons[i].addon_id, quantity: editaddons[i].addons_service_qty, type: editaddons[i].new });
    }
    formData.append("addons_service_qty", JSON.stringify(addons));

    updaterecurringbookingAPi(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          // alert("Booking updated successfully");
          handleClose3();
          // handleClose3();
          handleClose2();
          setEventcount(eventcount + 1);
          window.location.reload();
        } else {
          alert("Something went wrong");
        }
      } else {
        alert("Something went wrong");
      }
    }).catch((error) => {
      //console.log(error);
    }).finally(() => {
      setApicall(false);
    })

  }

  const calculatePrice = () => {
    var totalAmountvar = 0;
    var totalDurationvar = 0;
    subService.map((item) => {
      const basePrice = parseInt(item.base_price);
      const baseCount = 1;
      const baseDuration = parseInt(item.base_duration);
      const extraCount = item.quantity - baseCount;
      const extraPrice = parseInt(item.base_price_extra_unit);
      const extraDuration = parseInt(item.extra_duration_per_unit_in_minute);
      const baseAmount = basePrice * baseCount;
      const extraAmount = extraPrice * extraCount;
      totalAmountvar = totalAmountvar + baseAmount + extraAmount;
      totalDurationvar = totalDurationvar + baseDuration + extraCount * extraDuration;
    });
    addons.map((item) => {
      if (item.quantity == 0) {
      }
      else {
        const basePrice = parseInt(item.base_price);
        const baseCount = 1;
        const baseDuration = parseInt(item.base_duration);;
        const extraCount = item.quantity - baseCount;
        const extraPrice = parseInt(item.price_for_per_extra_unit);
        const extraDuration = parseInt(item.extra_minute_per_unit);
        const baseAmount = basePrice * baseCount;
        const extraAmount = extraPrice * extraCount;
        totalAmountvar = totalAmountvar + baseAmount + extraAmount;
        totalDurationvar = totalDurationvar + baseDuration + extraCount * extraDuration;
      }

    });

    setTotalAmount(totalAmountvar);
    setTotalDuration(parseInt(totalDurationvar));
    // setTotalDuration(parseInt(totalDurationvar / ChooseStaff.length));

    //get percentage from totalAmount 
    const percentage = (totalAmountvar * servicepercentage) / 100;
    const totalAmountvar1 = totalAmountvar + percentage;
    setExpectedTotalAmount(totalAmountvar1);


    //get percentage from totalDurationvar 
    const extrarange = (totalDurationvar * servicepercentage) / 100;
    const totalDurationvarwithextra = totalDurationvar + extrarange;

    setBasewithextra(parseInt(totalDurationvarwithextra));
    setUpdatedendtime(parseInt(totalDurationvarwithextra));

  }

  const confirmbooking = () => {
    //confirm alert
    if (window.confirm("Are you sure you want to confirm this booking?")) {
      const formData = new FormData();
      formData.append("order_id", bookingDetails.order_id);
      confirmbookingstatus(formData).then((response) => {
        if (response.code === 200) {
          if (response.data.status === "1") {
            alert("Booking confirmed successfully");
            handleClose2();
            setEventcount(eventcount + 1);
            // getbookingsfunc();
            // window.location.reload();
          } else {
            alert("Something went wrong");
          }
        } else {
          alert("Something went wrong");
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  const rejectbooking = () => {
    if (window.confirm("Are you sure you want to reject this booking?")) {
      const formData = new FormData();
      formData.append("order_id", bookingDetails.order_id);
      rejectbookingstatus(formData).then((response) => {
        if (response.code === 200) {
          if (response.data.status === "1") {
            // alert("Booking remove successfully");
            // handleClose4();
            setEventcount(eventcount + 1);
            // window.location.reload();
          } else {
            alert("Something went wrong");
          }
        } else {
          alert("Something went wrong");
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  const [addClasss, setAddClasss] = useState(false);

  const deletebooking = () => {
    // if (window.confirm("Are you sure you want to delete this booking?")) {
    const formData = new FormData();
    formData.append("order_id", bookingDetails.order_id);
    deletebookingApi(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          // alert("Booking delete successfully");
          NotificationManager.success("Booking delete successfully");
          handleClose2();
          setEventcount(eventcount + 1);
          // window.location.reload();
        } else {
          alert("Something went wrong");
        }
      } else {
        alert("Something went wrong");
      }
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      setAddClasss(false);
    })
    // }
  }

  const deletereccuringbooking = () => {
    if (window.confirm("Are you sure you want to delete this booking?")) {
      const formData = new FormData();
      formData.append("recurring_id", bookingDetails.recurring_id);
      formData.append("booking_date", bookingDetails.appointment_starttime_test);

      reccuringdeletebooking(formData).then((response) => {
        if (response.code === 200) {
          if (response.data.status === "1") {
            NotificationManager.success("Booking delete successfully");
            // handleClose4();
            // window.location.reload();
            setEventcount(eventcount + 1);
          } else {
            alert("Something went wrong");
          }
        } else {
          alert("Something went wrong");
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  const [rescheduleDetails, setRescheduleDetails] = useState([]);
  const [selectstaffs, setSelectstaffs] = useState([]);
  const [rescheduleDate, setRescheduleDate] = useState(rescheduleDetails.order_date);
  const [reschedunotes, setReschedulenotes] = useState(rescheduleDetails.order_time);

  useEffect(() => {
  }, [selectstaffs]);

  useEffect(() => {
    setRescheduleDate(rescheduleDetails.order_date);
    setReschedulenotes(rescheduleDetails.app_notes);
  }, [rescheduleDetails]);


  useEffect(() => {
    // setSelectstaffs(rescheduleDetails.staff_ids);
    //set select staff ids in array
    const staff_ids = rescheduleDetails.staff_ids;
    if (staff_ids > 0) {
      const staff_ids_array = staff_ids.split(",");
      const staff_ids_index = [];
      staff_ids_array.forEach((staff_id) => {
        const index = staffList.findIndex((staff) => staff.value === staff_id);
        staff_ids_index.push(index);
      }
      );
      const selectedStaff = [];
      staff_ids_index.forEach((index) => {
        selectedStaff.push(staffList[index]);
      }
      );
      setSelectstaffs(selectedStaff);
    }

  }, [rescheduleDetails]);

  const rescheduleData = () => {
    handleShow5();

    const formData = new FormData();
    formData.append("order_id", bookingDetails.order_id);

    getrescheduledata(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          setRescheduleDetails(response.data.data);
        } else {
          alert("Something went wrong");
        }
      } else {
        alert("Something went wrong");
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  const [startDate, setStartDate] = useState(moment(dateData).format("YYYY-MM-DD"));
  const [changestaffzone, setchangesstaffzone] = useState('');
  const [staffListtttt, setStaffListtttt] = useState(null);


  useEffect(() => {
    getStaffListtt();
  }, [changestaffzone]);

  const getStaffListtt = () => {
    getStaff(changestaffzone).then((res) => {
      if (res.code === 200) {
        const apiResponse = res.data;
        if (res.data.status === "1") {
          setStaffListtttt(apiResponse.data);
        }
        else {
          alert("data not found");
          setStaffListtttt([]);
        }

      }
    })
      .catch((err) => {
        console.log(err);
      });
  };



  const [Frequency, setFrequency] = useState([]);


  const [CustomWeekmonth, setCustomWeekmonth] = useState([]);
  const [CustomWeeksmonths, setCustomWeeksmonths] = useState([]);


  useEffect(() => {
    if (CustomWeeksmonths === "weeks") {
      document.getElementById("my_time_week1").style.display = "block";
      document.getElementById("my_time_week2").style.display = "none";
    }
    if (CustomWeeksmonths === "months") {
      document.getElementById("my_time_week2").style.display = "block";
      document.getElementById("my_time_week1").style.display = "none";
    }

  }, [CustomWeeksmonths]);

  useEffect(() => {
    if (Frequency === "weekly") {
      document.getElementById("weekly").style.display = "block";
      document.getElementById("monthly").style.display = "none";
      document.getElementById("custom").style.display = "none";
      document.getElementById("hide-sec").style.display = "none";
      setSummary([]);
      setCustomWeekmonth([]);
    }
    if (Frequency === "monthly") {
      document.getElementById("monthly").style.display = "block";
      document.getElementById("weekly").style.display = "none";
      document.getElementById("custom").style.display = "none";
      document.getElementById("hide-sec").style.display = "none";
      setSummary([]);
      setCustomWeekmonth([]);
    }
    if (Frequency === "0") {
      document.getElementById("monthly").style.display = "none";
      document.getElementById("weekly").style.display = "none";
      document.getElementById("custom").style.display = "none";
      document.getElementById("hide-sec").style.display = "none";
      setSummary([]);
      setCustomWeekmonth([]);
    }
    if (Frequency === "custom") {
      document.getElementById("custom").style.display = "block";
      document.getElementById("monthly").style.display = "none";
      document.getElementById("weekly").style.display = "none";
      document.getElementById("hide-sec").style.display = "none";
      setSummary([]);
      setCustomWeekmonth([]);
    }

  }, [Frequency]);

  const [summary, setSummary] = useState([]);
  const [bookingStartDate, setBookingStartDate] = useState(null);
  const [bookingEndDate, setBookingEndDate] = useState(null);
  const [recurringbookingdate, setRecurringbookingdate] = useState(null);

  const [selectedDays, setSelectedDays] = useState([]);



  useEffect(() => {
    if (selectedDays.length > 0) {
      let data = new FormData();
      data.append('selectedDays', JSON.stringify(selectedDays));
      getDates(data).then((response) => {
        if (response.code === 200) {
          if (response.data.status === "1") {
            const apiResponse = response.data.data;
            apiResponse.map((item, index) => {
              if (index === 0) {
                setBookingStartDate(item);
              }
              if (index === response.data.data.length - 1) {
                setBookingEndDate(item);
              }
            });

            setRecurringbookingdate(response.data.data);
          } else {
            console.log("response: error");
          }
        } else {
          console.log("response: error");
        }
      }).catch((error) => {
        console.log("response: error");
      });
    }
  }, [selectedDays]);


  const [customedateobj, setCustomedateobj] = useState([]);
  const [bookingzonelist, setBookingzonelist] = useState([]);


  useEffect(() => {
    if (customedateobj.length > 0) {
      let data = new FormData();
      data.append('selectedDays', JSON.stringify(customedateobj));
      getDates(data).then((response) => {
        if (response.code === 200) {
          if (response.data.status === "1") {
            const apiResponse = response.data.data

            apiResponse.map((item, index) => {
              if (index === 0) {
                setBookingStartDate(item);
              }
              if (index === response.data.data.length - 1) {
                setBookingEndDate(item);
              }
            });

            setRecurringbookingdate(response.data.data);

          } else {
            console.log("response: error");
          }
        } else {
          console.log("response: error");
        }
      }).catch((error) => {
        console.log("response: error");
      });
    }

  }, [customedateobj]);


  useEffect(() => {
    getallzone();
  }, []);

  const getallzone = () => {
    getZone().then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          setBookingzonelist(response.data.data);
        } else {
          console.log("response: error");
        }
      } else {
        console.log("response: error");
      }
    }).catch((error) => {
      console.log("response: error");
    });
  }

  const [cardNumber, setCardNumber] = useState('');
  const [cardExpDate, setCardExpDate] = useState('');
  const [cardCVV, setCardCVV] = useState('');
  const [fullName, setFullName] = useState('');

  const { getExpiryDateProps } = usePaymentInputs();


  const [cardNumbererror, setCardNumbererror] = useState(false);
  const [cardCVVerror, setCardCVVerror] = useState(false);
  const [cardExpDateerror, setCardExpDateerror] = useState(false);
  const [fullNamerror, setFullNamerror] = useState(false);
  const [cardExpYearerror, setCardExpYearerror] = useState(false);

  const handleAddCard = () => {

    if (cardNumber == '' || cardNumber.length != 19) {
      setCardNumbererror(true);
    } else {
      setCardNumbererror(false);
    }

    if (cardCVV == '' || cardCVV.length != 3) {
      setCardCVVerror(true);
    } else {
      setCardCVVerror(false);
    }

    if (cardExpDate == '') {
      setCardExpDateerror(true);
    }

    if (fullName == '') {
      setFullNamerror(true);
    } else {
      setFullNamerror(false);
    }

    const expDateParts = cardExpDate.split('/');

    const expMonth = parseInt(expDateParts[0]);
    const expYear = parseInt(expDateParts[1]);

    const currentMonth = new Date().getMonth() + 1; // Month is 0-indexed, so add 1
    const currentYear = new Date().getFullYear() % 100; // Get the last two digits of the current year

    if (expYear < currentYear || (expYear === currentYear && expMonth < currentMonth)) {
      // alert("Card expiration date should be in the future.");
      setCardExpYearerror(true);
      return;
    } else {
      setCardExpYearerror(false);
    }

    const name = fullName.split(' ');
    if (name.length < 2) {
      setFullNamerror(true);
      return;
    } else {
      setFullNamerror(false);
    }



    const formData = new FormData();
    formData.append('customer_id', bookingDetails.customer_id);
    formData.append("card_number", cardNumber);
    formData.append("person_name", fullName);
    formData.append("card_cvv", cardCVV);
    formData.append("card_exp", cardExpDate);

    createPaymentMethod(formData).then((res) => {
      if (res.code === 200) {
        alert("credentials added successfully");
        handleClose6();
      }
      else {

        alert("Something went wrong");
      }
    }).catch((err) => {
      console.log("err", err);
    });

  }

  const complatebooking = () => {
    if (window.confirm("Are you sure you want to complete this booking?")) {
      const formData = new FormData();
      formData.append("order_id", bookingDetails.order_id);
      complatebookingstatus(formData).then((response) => {
        if (response.code === 200) {
          if (response.data.status === "1") {
            // alert("Booking completed successfully");
            // getbookingsfunc();
            handleClose2();
            // window.location.reload();
            setEventcount(eventcount + 1);
          } else {
            alert("Something went wrong");
          }
        } else {
          alert("Something went wrong");
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  const uncomplatebooking = () => {
    if (window.confirm("Are you sure you want to uncomplete this booking?")) {
      const formData = new FormData();
      formData.append("order_id", bookingDetails.order_id);
      uncomplatebookingstatus(formData).then((response) => {
        if (response.code === 200) {
          if (response.data.status === "1") {
            // alert("Booking uncompleted successfully");
            // getbookingsfunc();
            handleClose2();
            // window.location.reload();
            setEventcount(eventcount + 1);
          } else {
            alert("Something went wrong");
          }
        } else {
          alert("Something went wrong");
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  }


  const unconfirmbooking = () => {
    if (window.confirm("Are you sure you want to unconfirm this booking?")) {
      const formData = new FormData();
      formData.append("order_id", bookingDetails.order_id);
      unconfirmbookingstatus(formData).then((response) => {
        if (response.code === 200) {

          if (response.data.status === "1") {
            // alert("Booking unconfirmed successfully");
            // getbookingsfunc();
            handleClose2();
            // window.location.reload();
            setEventcount(eventcount + 1);
          } else {
            alert("Something went wrong");
          }
        } else {
          alert("Something went wrong");
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  const activebooking = () => {
    if (window.confirm("Are you sure you want to active this booking?")) {
      const formData = new FormData();
      formData.append("order_id", bookingDetails.order_id);
      activebookingstatus(formData).then((response) => {
        if (response.code === 200) {
          if (response.data.status === "1") {
            // alert("Booking active successfully");
            // getbookingsfunc();
            handleClose2();
            // window.location.reload();
            setEventcount(eventcount + 1);
          } else {
            alert("Something went wrong");
          }
        } else {
          alert("Something went wrong");
        }
      }).catch((error) => {
        console.log(error);
      });
    }

  }

  const createStaxId = () => {

    const formData = new FormData();
    formData.append("client_name", bookingDetails.client_name);
    formData.append("client_id", bookingDetails.client_id);
    formData.append("client_phone", bookingDetails.client_phone);
    formData.append("client_address", bookingDetails.client_address);
    formData.append("clientemail", bookingDetails.client_email);

    createStaxIdApi(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          NotificationManager.success("Stax Id created successfully");
          //get today booking
          window.location.reload();
          // getbookingsfunc();
          // handleClose2();
        } else {
          NotificationManager.error(response.data.message);
        }
      } else {
        alert("Something went wrong");
      }

    }).catch((error) => {
      console.log(error);
    });
  }



  const [invoiceprice, setInvoiceprice] = useState();
  const [invoicenotes, setInvoicenotes] = useState('');
  const [invoiceservice, setInvoiceservice] = useState([]);

  const [cleaningtime, setCleaningTime] = useState();
  const [bookingtimesheetcleaningtime, setBookingtimesheetcleaningtime] = useState();
  const [bookingtimenote, setBookingtimenote] = useState();


  const getStatus = (status) => {
    if (status === "0") {
      return "(Admin Booked)";
    }
    if (status === "1") {
      return "(Client Booked)";
    }
    if (status === "2") {
      return "(Technician Booked)";
    }
  }


  const getsubserviceaddons = () => {
    const formData = new FormData();
    formData.append("order_id", bookingDetails.order_id);
    getsubserviceaddonsApi(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          setInvoiceservice(response.data.data);

          handleShow4();
        } else {
          alert("Something went wrong");
        }
      } else {
        alert("Something went wrong");
      }

    }).catch((error) => {
      console.log(error);
    });
  }


  const [invoiceList, setInvoiceList] = useState([]);

  //invoice loader state
  const [invoiceLoader, setInvoiceLoader] = useState(false);

  const getinvoice = () => {
    // setLoading(true);
    setInvoiceLoader(true);
    const customer_id = bookingDetails.order_id;
    getinvoiceApi(customer_id).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          setInvoiceList(response.data.data);
        } else {
          setInvoiceList([]);
          NotificationManager.info("No invoice found");
        }
      } else {
        alert("Something went wrong");
      }

    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      // setLoading(false);
      setInvoiceLoader(false);
    });
  }

  const [keywords, setKeywords] = useState('');

  const handleChange = (e) => {
    setKeywords(e.target.value);
  }


  const columns = [{
    dataField: 'id',
    text: 'Id',
    formatter: (cell, row, rowIndex, formatExtraData) => (
      <div>{rowIndex + 1}</div>
    ),
    sort: true
  }, {
    dataField: 'id',
    text: 'INVOICE ID',
    sort: true
  },
  {
    dataField: 'created_at',
    text: 'INVOICE DATE',
    sort: true,
    formatter: (cell, row) => {
      const date = row.created_at;
      const newDate = new Date(date);
      var day = newDate.getDate();
      day = day < 10 ? '0' + day : day;
      const month = newDate.toLocaleString('default', { month: 'short' });
      const year = newDate.getFullYear();
      var hours = newDate.getHours();
      hours = hours < 10 ? '0' + hours : hours;
      var minutes = newDate.getMinutes();
      minutes = minutes < 10 ? '0' + minutes : minutes;
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const formattedDate = `${day} ${month} ${year} ${hours}:${minutes} ${ampm}`;
      return (
        <div>{formattedDate}</div>
      )
    }
  },
  {
    dataField: 'total',
    text: 'AMOUNT',
    sort: true
  },
  {
    text: 'STATUS',
    isDummy: true,
    formatter: (cell, row) => {
      const status = row.status;
      if (status === "DRAFT" || status === "PENDING" || status === "ATTEMPTED") {
        return (
          <Button className="Enable-Active-bttn invoice-list-btn" onClick={(id) => getpaymentCards(row.id, row.total)}>PAY Now</Button>
        )
      }
      // else if(status === "ATTEMPTED"){
      //   return (
      //     "Unsuccessful"
      //   )
      // }
      else {
        return (
          status
        )
      }
    }
  },
  {
    text: 'ACTION',
    isDummy: true,
    formatter: (cell, row) => {
      const status = row.status;
      if (status === "PAID") {
        return (
          "PAID"
        )
      }
      else if (status === "ATTEMPTED") {
        return;
      }
      else {
        return (
          <div className="button-group">
            <Button className="Delete-Active-bttn me-2"><RiDeleteBin6Line /></Button>
          </div>
        )
      }
    }
  }];

  const [showModal7, setShowModal] = useState(false);

  const handleClose7 = () => setShowModal(false);
  const handleShow7 = () => setShowModal(true);


  const [selectedCard, setSelectedCard] = useState('');
  const [paymentMethod, setPaymentMethod] = useState([]);

  const [paymentMethodId, setPaymentMethodId] = useState('');
  const [invoiceId, setInvoiceId] = useState('');
  const [invoicetotal, setInvoicetotal] = useState('');

  const getpaymentCards = (id, total) => {
    setShowModal(true);
    setInvoiceId(id);
    setInvoicetotal(total);
    const formData = new FormData();
    formData.append("customer_id", bookingDetails.customer_id);

    getpaymentMethod(formData).then((res) => {
      if (res.code == 200) {
        if (res.data.status === "1") {
          const paymentMethod = res.data.data;
          setPaymentMethod(paymentMethod);
          if (paymentMethod && paymentMethod.length != 0 && !selectedCard) {
            if (bookingDetails.card_id != null) {
              const selectedPaymentMethod = paymentMethod.find((item) => item.id === bookingDetails.card_id);
              if (selectedPaymentMethod) {
                setPaymentMethodId(selectedPaymentMethod.id);
                setSelectedCard(`cardOption${selectedPaymentMethod.id}`);
              }
            }
            else {
              setSelectedCard(`cardOption${paymentMethod[0].id}`);
              setPaymentMethodId(paymentMethod[0].id);
            }
          }

        } else {
          alert("Please Add card first");
          handleClose7();
        }
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  const payinvoice = () => {
    const formData = new FormData();
    formData.append("payment_method_id", paymentMethodId);
    formData.append("apply_balance", invoicetotal);
    formData.append("invoiceId", invoiceId);

    payinvoiceApi(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          const status = response.data.data.status;
          if (status === "ATTEMPTED" || status === "DRAFT" || status === "PENDING") {
            NotificationManager.error("Your invoice payment was unsuccessful. Try again");
          } else {
            NotificationManager.success("Your invoice has been paid");
            getinvoice();
          }

          handleClose7();

        } else {
          alert("Something went wrong");
        }
      } else {
        alert("Something went wrong");
      }

    }
    ).catch((error) => {
      console.log(error);
    }
    );
  }


  const states = [
    { name: "Alabama", cities: ["Birmingham", "Montgomery", "Mobile"], stateCode: "AL" },
    { name: "Alaska", cities: ["Anchorage", "Fairbanks", "Juneau"], stateCode: "AK" },
    { name: "Arizona", cities: ["Phoenix", "Tucson", "Mesa"], stateCode: "AZ" },
    { name: "Arkansas", cities: ["Little Rock", "Fort Smith", "Fayetteville"], stateCode: "AR" },
    { name: "California", cities: ["Los Angeles", "San Diego", "San Jose"], stateCode: "CA" },
    { name: "Colorado", cities: ["Denver", "Colorado Springs", "Aurora"], stateCode: "CO" },
    { name: "Connecticut", cities: ["Bridgeport", "New Haven", "Hartford"], stateCode: "CT" },
    { name: "Delaware", cities: ["Wilmington", "Dover", "Newark"], stateCode: "DE" },
    { name: "Florida", cities: ["Jacksonville", "Miami", "Tampa"], stateCode: "FL" },
    { name: "Georgia", cities: ["Atlanta", "Augusta", "Columbus"], stateCode: "GA" },
    { name: "Hawaii", cities: ["Honolulu", "Pearl City", "Hilo"], stateCode: "HI" },
    { name: "Idaho", cities: ["Boise", "Nampa", "Meridian"], stateCode: "ID" },
    { name: "Illinois", cities: ["Chicago", "Aurora", "Rockford"], stateCode: "IL" },
    { name: "Indiana", cities: ["Indianapolis", "Fort Wayne", "Evansville"], stateCode: "IN" },
    { name: "Iowa", cities: ["Des Moines", "Cedar Rapids", "Davenport"], stateCode: "IA" },
    { name: "Kansas", cities: ["Wichita", "Overland Park", "Kansas City"], stateCode: "KS" },
    { name: "Kentucky", cities: ["Louisville", "Lexington", "Bowling Green"], stateCode: "KY" },
    { name: "Louisiana", cities: ["New Orleans", "Baton Rouge", "Shreveport"], stateCode: "LA" },
    { name: "Maine", cities: ["Portland", "Lewiston", "Bangor"], stateCode: "ME" },
    { name: "Maryland", cities: ["Baltimore", "Frederick", "Rockville"], stateCode: "MD" },
    { name: "Massachusetts", cities: ["Boston", "Worcester", "Springfield"], stateCode: "MA" },
    { name: "Michigan", cities: ["Detroit", "Grand Rapids", "Warren"], stateCode: "MI" },
    { name: "Minnesota", cities: ["Minneapolis", "Saint Paul", "Rochester"], stateCode: "MN" },
    { name: "Mississippi", cities: ["Jackson", "Gulfport", "Southaven"], stateCode: "MS" },
    { name: "Missouri", cities: ["Kansas City", "Saint Louis", "Springfield"], stateCode: "MO" },
    { name: "Montana", cities: ["Billings", "Missoula", "Great Falls"], stateCode: "MT" },
    { name: "Nebraska", cities: ["Omaha", "Lincoln", "Bellevue"], stateCode: "NE" },
    { name: "Nevada", cities: ["Las Vegas", "Henderson", "Reno"], stateCode: "NV" },
    { name: "New Hampshire", cities: ["Manchester", "Nashua", "Concord"], stateCode: "NH" },
    { name: "New Jersey", cities: ["Newark", "Jersey City", "Paterson"], stateCode: "NJ" },
    { name: "New Mexico", cities: ["Albuquerque", "Las Cruces", "Rio Rancho"], stateCode: "NM" },
    { name: "New York", cities: ["New York", "Buffalo", "Rochester"], stateCode: "NY" },
    { name: "North Carolina", cities: ["Charlotte", "Raleigh", "Greensboro"], stateCode: "NC" },
    { name: "North Dakota", cities: ["Fargo", "Bismarck", "Grand Forks"], stateCode: "ND" },
    { name: "Ohio", cities: ["Columbus", "Cleveland", "Cincinnati"], stateCode: "OH" },
    { name: "Oklahoma", cities: ["Oklahoma City", "Tulsa", "Norman"], stateCode: "OK" },
    { name: "Oregon", cities: ["Portland", "Salem", "Eugene"], stateCode: "OR" },
  ];


  const [servicepreferences, setServicepreferences] = useState([]);


  useEffect(() => {
    getservicesforuser();
  }, [edituser]);


  const getservicesforuser = () => {
    const formData = new FormData();
    formData.append("user_id", edituser.id);
    getbookingservicesApi(formData).then((res) => {
      if (res.code === 200) {
        const apiResponse = res.data;
        setServicepreferences(apiResponse.data);
      } else {
        alert("Something went wrong");
      }
    });
  };



  const getscheduledtime = (booking_duration, staff_ids, order_date) => {

    if (booking_duration != "" && booking_duration != null && staff_ids != "" && staff_ids != null) {

      // Extract hours and minutes from booking_duration
      const durationParts = booking_duration.split(" ");
      const hours = parseInt(durationParts[0]);
      const minutes = parseInt(durationParts[2]);

      // Calculate total duration in minutes
      const totalMinutes = hours * 60 + minutes;

      //check order_date is less than and equal to today 
      // const today = "2024-01-29";
      // const date = new Date(order_date);
      // if (date <= today) {
      //   return `${hours} hours ${minutes} minutes`;
      // }
      // else {
      const staffIdsArray = staff_ids.split(",");

      const resultHours = Math.floor(totalMinutes / staffIdsArray.length / 60);
      const resultMinutes = totalMinutes / staffIdsArray.length % 60;

      return `${resultHours} hours ${parseInt(resultMinutes)} minutes`;
      // }
    }

  }

  const gettechnicialtime = (booking_timesheet_cleaning_time) => {
    if (Array.isArray(booking_timesheet_cleaning_time) && booking_timesheet_cleaning_time.length > 0) {
      const formattedNotes = booking_timesheet_cleaning_time.map(item => `${item.fullname} notes: ${convertToHHMM(item.time_difference)}`);
      return formattedNotes.join(', ');
    }
  }

  const gettechtimesheetnotes = (bookingNotes) => {
    if (Array.isArray(bookingNotes) && bookingNotes.length > 0) {
      const formattedNotes = bookingNotes.map(item => `${item.fullname} notes: ${item.note}`);
      return formattedNotes.join(', ');
    }
  }

  const getdividetime = (startTime, booking_duration, staff_ids) => {

    if (booking_duration != "" && booking_duration != null && staff_ids != "" && staff_ids != null) {
      const durationParts = booking_duration.split(" ");
      const hours = parseInt(durationParts[0]);
      const minutes = parseInt(durationParts[2]);

      const totalMinutes = hours * 60 + minutes;

      const numStaff = staff_ids.split(",").length;
      const timePerStaff = totalMinutes / numStaff;

      const hoursPerStaff = Math.floor(timePerStaff / 60).toString().padStart(2, '0');

      //i want to time like 01:45
      const minutesPerStaff = (timePerStaff % 60).toString().padStart(2, '0');


      const date = moment(startTime, 'HH:mm:ss');

      return moment(date).add(hoursPerStaff, 'hours').add(minutesPerStaff, 'minutes').format('HH:mm');


    }

  }

  const [editbookingtimeEnd, setEditbookingtimeEnd] = useState('');

  useEffect(() => {
    getCalculatedEndTime(editbooking.start_time, expedtedendtime);
  }, [editbooking.start_time, expedtedendtime]);


  function getCalculatedEndTime(starttime, endtimeInMinutes) {
    const startMoment = moment(starttime, 'hh:mm A');
    const endMoment = startMoment.clone().add(endtimeInMinutes, 'minutes');

    // Format the result as "HH:mm"
    const formattedEndTime = endMoment.format('HH:mm');

    // return formattedEndTime;
    setEditbookingtimeEnd(formattedEndTime);
  }

  const handleStartTimeChange = (e) => {
    const newStartTime = e.target.value;
    setEditbookingStartTime(newStartTime);

    const startMoment = moment(newStartTime, 'hh:mm A');
    const endMoment = startMoment.clone().add(expedtedendtime, 'minutes');

    const formattedEndTime = endMoment.format('HH:mm');
    setEditbookingtimeEnd(formattedEndTime);
  }


  const [usercrmid, setUsercrmid] = useState(0);


  const callChildFunction = (userid) => {
    setUsercrmid(userid);
  };


  const [rows, setRows] = useState([
    {
      id: '',
      service: '',
      notes: '',
      quantity: 1,
      price: 0,
    },
  ]);

  console.log("rowsrowsrowsrows",rows);

  useEffect(() => {

    const booking_discount = bookingDetails.booking_discount;

    if (Array.isArray(bookingtimesheetcleaningtime)) {
      // Map the services according to your conditions
      const serviceMapping = {
        "House Cleaning": "Scheduled Cleaning",
        "First Time General Cleaning": "First Time Cleaning",
        "First Time Deluxe Cleaning": "First Time Cleaning",
        "Move In/Out Service": "TBD",
        "Window Cleaning": "Extra Service",
        "Area Fee": "Area Fee"
      };

      const cleanedServiceTitle = bookingDetails.service_title.replace(':', '').trim();

      const service = serviceMapping[cleanedServiceTitle];

      const updatedRows = bookingtimesheetcleaningtime.map((staff, index) => {


        const rowobject = {
          id: bookingDetails.service_stax_id,
          service: service,
          notes: staff.fullname,
          quantity: convertToHM(staff.time_difference),
          price: 65,
          total: 65 * convertToHM(staff.time_difference),
        }
        return rowobject;
      });

      const rowobject2 = {
        // service: 'e92b0e79-55b6-4920-a7fa-16641bd2ee0e',
        id: bookingDetails.service_stax_id,
        service: service,
        notes: 'Base Fee',
        quantity: 1,
        price: 30,
        total: 30
      }
      const updatedRowsWithRowObject2 = [...updatedRows, rowobject2];

      // setRows(updatedRowsWithRowObject2);

      if (booking_discount !== undefined && booking_discount > 0 && booking_discount !== null) {
        const rowobject3 = {
          id: "f5e8658e-d19e-43b6-9cbb-33ba7c86ec7c",
          service: 'Discount',
          notes: '',
          quantity: 1,
          price: -booking_discount,
          total: -booking_discount
        };
        updatedRowsWithRowObject2.push(rowobject3);
      }

      setRows(updatedRowsWithRowObject2);

    } else {
      const services = [{
        id: '',
        service: '',
        notes: '',
        quantity: 1,
        price: 0,
      }];
      setRows(services);
    }
  }, [bookingtimesheetcleaningtime]);


  const addRow = () => {
    setRows([...rows, {
      service: '',
      notes: '',
      quantity: 0,
      price: 0,
    }]);
  };

  const updateRow = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const removeRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };


  function convertToHM(value) {
    const start_time = value;
    const hours = Math.floor(start_time / 60);
    const minutes = start_time % 60;

    // Calculate the total time in hours with decimal representation for minutes
    const totalHours = hours + (minutes / 60);

    return totalHours;
  }


  const [productlist, setproductlist] = useState([]);

  const [discountinvoice, setDiscountinoice] = useState(0);

  const productlistdata = () => {
    getproductlistdataApi().then((res) => {
      if (res.code === 200) {
        const apiResponse = res.data.data;
        setproductlist(apiResponse);
      } else {
        alert("Something went wrong");
      }
    });
  };

  const createinvoice = () => {

    if (invoicenotes == '') {
      alert("Please enter invoice notes");
      return false;
    }

    const formData = new FormData();
    var totalprice = 0;

    const lineItems = [];

    for (let i = 0; i < rows.length; i++) {
      const serviceParts = rows[i].service.split(' ');


      const data = {
        "id": rows[i].id || serviceParts[0], // Check if id exists; if not, use the first part of service
        "item": rows[i].id ? rows[i].service : serviceParts.slice(1).join(' '), // Use full service if id exists, otherwise exclude id part
        "details": rows[i].notes,
        "quantity": parseFloat(rows[i].quantity, 10),
        "price": parseFloat(rows[i].price)
      };
      lineItems.push(data);

      // Calculate the total price for this item and add it to the totalprice
      totalprice += data.quantity * data.price;
    }


    formData.append("customer_id", bookingDetails.customer_id);
    formData.append("total", calculateTotal(rows, discountinvoice));
    formData.append("subtotal", calculateTotal(rows, discountinvoice));
    // formData.append("notes", invoicenotes);
    formData.append("order_id", bookingDetails.order_id);
    formData.append("booking_date_time", bookingDetails.appointment_starttime);

    formData.append("line_item", JSON.stringify(lineItems));

    createinvoiceApi(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          NotificationManager.success("Invoice created successfully");
          getinvoice();
        } else {
          NotificationManager.error(response.data.message);
        }
      } else {
        alert("Something went wrong");
      }

    }).catch((error) => {
      console.log(error);
    });

  }

  const onCardNumberChange = (event) => {
    let { value, name } = event.target;
    let cardNumber = value;
    value = value.replace(/\D/g, '');
    if (/^3[47]\d{0,13}$/.test(value)) {
      cardNumber = value
        .replace(/(\d{4})/, '$1 ')
        .replace(/(\d{4}) (\d{6})/, '$1 $2 ');
    } else if (/^3(?:0[0-5]|[68]\d)\d{0,11}$/.test(value)) {
      // diner's club, 14 digits
      cardNumber = value
        .replace(/(\d{4})/, '$1 ')
        .replace(/(\d{4}) (\d{6})/, '$1 $2 ');
    } else if (/^\d{0,16}$/.test(value)) {
      // regular cc number, 16 digits
      cardNumber = value
        .replace(/(\d{4})/, '$1 ')
        .replace(/(\d{4}) (\d{4})/, '$1 $2 ')
        .replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ');
    }

    setCardNumber(cardNumber.trimRight());
  };


  const addClass = () => {
    // document.querySelector(".view-more-content-hide").classList.add("active");
    // document.querySelector(".less-btn").classList.add("active");
    // document.querySelector(".more-btn").classList.add("active");
    // setCollapse(!collapse);
  };

  const removeClass = () => {
    // document
    //   .querySelector(".view-more-content-hide")
    //   .classList.remove("active");
    // document.querySelector(".less-btn").classList.remove("active");
    // document.querySelector(".more-btn").classList.remove("active");
    // setCollapse(!collapse);
  };


  return (
    <div>

      {/* {
        loading ? (
          <div className="loading">
            <Roller />
          </div>
        ) : (<> */}

      {/* Add Card modal start here */}
      <Modal show={show6} className="Sidebar-Modal-end" onHide={handleClose6} >
        <Modal.Header closeButton>
          <Modal.Title>New Card Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className='custum-modal-body'>
          <div className='row'>
            <div className='col-md-12'>
              <Form.Group className="mb-4" controlId="validationCustom01">
                <Form.Label className='custom-lable'>Card Number</Form.Label>
                <Form.Control
                  type="tel"
                  className='custom-input'
                  value={cardNumber}
                  onChange={onCardNumberChange}
                  maxLength="19" />
                {cardNumbererror == true ? <p style={{ color: 'red' }}>card number is required</p> : null}
              </Form.Group>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6'>
              <Form.Group className="mb-4" controlId="validationCustom01">
                <Form.Label className='custom-lable'>Exp. Date</Form.Label>
                <Form.Control required type="Text" placeholder="MM/YY" className='custom-input'
                  value={cardExpDate}
                  {...getExpiryDateProps({ onChange: (e) => setCardExpDate(e.target.value) })} />
                {cardExpDateerror == true ? <p style={{ color: 'red' }}>please enter card exipry date</p> : ''}
                {cardExpYearerror == true ? <p style={{ color: 'red' }}>Card expiration date should be in the future.</p> : ''}
              </Form.Group>
            </div>
            <div className='col-md-6'>
              <Form.Group className="mb-4" controlId="validationCustom01">
                <Form.Label className='custom-lable'>CVV</Form.Label>
                <Form.Control required type="number" placeholder="CVV" className='custom-input'
                  value={cardCVV}
                  onInput={
                    (e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3)
                    }
                  }
                  onChange={(e) => setCardCVV(e.target.value)} />
                {cardCVVerror == '' ? <p style={{ color: 'red' }}>please enter cvv</p> : ''}
                {cardCVV.length != 3 ? <p style={{ color: 'red' }}>cvv must have 3 digits</p> : ''}
              </Form.Group>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12'>
              <Form.Group className="mb-4" controlId="validationCustom01">
                <Form.Label className='custom-lable'>Full Name</Form.Label>
                <Form.Control required type="text" placeholder="Full Name" className='custom-input'
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)} />
                {fullNamerror == true ? <p style={{ color: 'red' }}>please enter full name</p> : ''}
              </Form.Group>
            </div>
          </div>

          <div className='row'>
            <div className='modal-btn justify-content-end'>
              <button href="#" className='btn g-btn me-3' type="submit" onClick={handleAddCard} >Save</button>
              <button href="#" className='btn btn-danger' onClick={handleClose6}>Cancel</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* modal End here */}

      {/* Payment Method modal End here */}
      <Modal show={showModal7} className="Sidebar-Modal-end" onHide={handleClose7}>
        <Modal.Header closeButton>
          <Modal.Title>Fattmerchant Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body className='custum-modal-body'>

          {paymentMethod && paymentMethod.length != 0 && paymentMethod.map((item, index) => {
            const cardOptionId = `cardOption${index}`;
            const isFirstCard = index === 0;
            const isChecked = selectedCard === cardOptionId || (isFirstCard && !selectedCard);
            return (
              <div class="form-check mb-3">
                <div className="d-flex align-items-center">
                  <input
                    className="form-check-input me-3"
                    type="radio"
                    name="cardOption"
                    id={`cardOption${index}`}
                    value={`cardOption${index}`}
                    checked={isChecked}
                    defaultChecked={index === 0}
                    onChange={
                      (e) => {
                        setSelectedCard(e.target.value);
                        setPaymentMethodId(item.id);
                      }
                    }
                  />
                  <label class="form-check-label payment-card-details" for="flexRadioDefault1">
                    <div className="card payment-card-details">
                      <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                          <div>
                            <div className="card-title h5">****-****-****-{
                              item.card_last_four || " "
                            }</div>
                            <p className="card-text">{
                              item.nickname || " "
                            }</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            );
          })}
          <div className='row'>
            <div className='modal-btn justify-content-end'>
              <button href="#" className='btn g-btn me-3' type="submit" onClick={payinvoice}>Save</button>
              <button href="#" className='btn btn-danger' onClick={handleClose7}>Cancel</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Payment Method modal End here */}


      {/* edit appointment modal start */}
      <Modal show={show4} onHide={handleClose4} className="Sidebar-Modal-end">
        <Modal.Header closeButton>
          <Modal.Title>Card Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-8">
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Stax Id:</Form.Label>
              </Form.Group>
              <div className="">
                <div className="create-stax-id mb-3">
                  {
                    bookingDetails.customer_id == "" ?
                      <button className="btn btn-primary actions-btn" onClick={createStaxId}>
                        Create Stax Id
                      </button>
                      :
                      <p>{bookingDetails.customer_id}</p>

                  }
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="payment-settings-content-header create-stax-id-btn mb-3">
                <button href="#" class="btn Add-bttn ms-auto" onClick={handleShow6}>
                  <span>
                    <BsPlusLg />
                  </span>
                  Add Card
                </button>
              </div>
            </div>
          </div>
          <div
            className=""
            style={{
              display: bookingDetails.customer_id === "" ? "none" : "block",
            }}
          >
            <div className="booking-details-deta-inner">
              <ul className="ct-cal-booking-details">
                <li className="ct-cal-booking-details-item">
                  <label>Price</label>
                  <span>: {`$` + invoiceprice}</span>
                </li>
                <li className="ct-cal-booking-details-item">
                  <label>Notes</label>
                  <span>{invoicenotes === " "
                    ? " "
                    : getNotes(invoicenotes)}{" "}</span>
                </li>
                <li className="ct-cal-booking-details-item">
                  <label>Team Cleaning Time</label>
                  <span>: {cleaningtime}</span>
                </li>
                <li className="ct-cal-booking-details-item">
                  <label>Technician Time</label>
                  <span>: {gettechnicialtime(bookingtimesheetcleaningtime)}</span>
                </li>
                <li className="ct-cal-booking-details-item">
                  <label>Timesheet Notes</label>
                  <span>: {gettechtimesheetnotes(bookingDetails.booking_time_note)}</span>
                </li>
              </ul>
            </div>

            <div className="payment-invoice mb-3">
              <div className="card">
                <div className="card-body">
                  <div className="card-title-invoice">
                    <h3>Invoice</h3>
                    <div className="add-invoice-btn">
                      <button className="btn-invoice" onClick={addRow}>< MdAdd />Add More</button>
                    </div>
                  </div>
                  <div className="invoice-list">
                    <table className="table table-invoice">
                      <thead>
                        <tr>
                          <th>DETAILS</th>
                          <th>QUANTITY</th>
                          <th>PRICE</th>
                          <th>AMOUNT</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rows.map((row, index) => (
                          <tr key={index} className="mt-3">
                            <td style={{ width: '100%' }}>
                              <div className="my-1 flex-nowrap input-group">
                                <Form.Select
                                  value={row.service}
                                  aria-label="Default select example"
                                  onChange={(e) => updateRow(index, 'service', e.target.value)}
                                >
                                  <option>Service Name</option>
                                  {productlist.map((item, itemIndex) => (
                                    <option key={itemIndex} value={`${item.id} ${item.item}`} selected={row.id === item.id}>
                                      {item.item}
                                    </option>
                                  ))}
                                </Form.Select>
                              </div>
                              <div className="my-1 flex-nowrap input-group">
                                <input
                                  name="notes"
                                  type="text"
                                  className="form-control"
                                  value={row.notes}
                                  onChange={(e) => updateRow(index, 'notes', e.target.value)}
                                />
                              </div>
                            </td>
                            <td style={{ width: '100%' }}>
                              <div className="my-1 flex-nowrap input-group">
                                <input
                                  name="quantity"
                                  type="text"
                                  className="form-control"
                                  value={row.quantity}
                                  onChange={(e) => {
                                    updateRow(index, 'quantity', e.target.value)
                                    const pricequantity = e.target.value;
                                    const total = parseFloat(pricequantity) * row.price;
                                    updateRow(index, 'total', total);
                                  }}
                                />
                              </div>
                            </td>
                            <td style={{ width: '100%' }}>
                              <div className="my-1 flex-nowrap input-group price-width">
                                <input
                                  name="Price"
                                  type="text"
                                  className="form-control"
                                  value={row.price}
                                  onChange={(e) => {
                                    const priceValue = e.target.value;
                                    updateRow(index, 'price', priceValue);
                                    const total = parseFloat(priceValue) * row.quantity;
                                    updateRow(index, 'total', total);
                                  }}
                                />
                              </div>
                            </td>
                            <td style={{ width: '100%' }}>
                              <b>{row.total ? `$${row.total}` : '$0'}</b>
                            </td>
                            {index === 0 ? (
                              <></>
                            ) : (
                              <button className="remove-row-btn" onClick={() => removeRow(index)}>
                                <MdClose />
                              </button>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-flex justify-content-between align-items-center gap-4 mb-3">
                      <div className="total">
                        <b>Total :</b>
                      </div>
                      <div className="w-50">
                        <div style={{ width: '100%' }}>
                          <div className="my-1 flex-nowrap input-group">
                            <input
                              name="discount"
                              type="text"
                              className="form-control"
                              value={`$${calculateTotal(rows, discountinvoice)}`}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="payment-settings-content-header d-flex justify-content-beetwin align-items-center mb-4">
              <button href="#" class="btn Add-bttn ms-auto" onClick={createinvoice} disabled={isNaN(calculateTotal(rows, discountinvoice)) || calculateTotal(rows, discountinvoice) < 0} >
                <span>
                  <BsPlusLg />
                </span>
                Create Invoice
              </button>
            </div>
            <hr />
            <div className="row">
              <h3>Invoice List</h3>
              <div className="col-md-12 mt-4">
                <div className="animated fadeIn">
                  {
                    invoiceLoader ? <Spinner animation="grow" variant="success" /> : (
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card">
                            <div className="card-header d-flex table-card-header">
                              <Form.Group>
                                <div className='d-flex'>
                                  <div addonType="prepend">
                                  </div>
                                  <Form.Control type="text" placeholder="Keywords..." onChange={handleChange} />
                                </div>
                              </Form.Group>
                            </div>
                            <div className="card-body">
                              <BootstrapTable bootstrap4 keyField='id' data={invoiceList} columns={columns} pagination={pagination} />
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose4}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* edit appointment modal end */}

      {/* edit appointment modal start */}
      <Modal show={showBookingDetails} onHide={() => { handleClose2(); onCloseidChangebooking(0); onCloseidChangeoldbookingid(order_id) }} className="Sidebar-Modal-end">
        <Modal.Header closeButton>
          <Modal.Title>Booking Details test</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="btn-header-list">
              <button style={{ display: "none" }} className="btn g-btn" onClick={
                (e) => {
                  getsubserviceaddons();
                  getinvoice();
                  setInvoiceprice(bookingDetails.price);
                }}>
                Fattmerchant Payment
              </button>
              {
                bookingDetails.booking_status != "CO" &&
                (
                  <button className="btn b-btn" onClick={getEditbooking}>
                    Edit Booking Detail
                  </button>
                )
              }

            </div>
            <div className="booking-details-deta">
              <div className="booking-details-deta-inner">
                <div className="booking-status">
                  <div className="w-40">Booking Status:</div>
                  <div className="booking-status-view">
                    <p>{getStatusofbookings(bookingDetails.booking_status)}{getStatus(bookingDetails.book_by)}</p>
                    <div className="time-list d-flex">

                      <div className="date-list-inner">
                        <TbCalendar />{" "}
                        {getDateFormate(bookingDetails.appointment_starttime)}
                      </div>
                      <div className="time-list-inner">
                        <TbClock /> {bookingDetails.appointment_start_time}
                      </div>
                    </div>
                    <div className="time-list d-flex">
                      <div className="date-list-inner">
                        <TbCalendar />{" "}
                        {getDateFormate(bookingDetails.appointment_starttime)}
                      </div>
                      <div className="time-list-inner">
                        {/* <TbClock /> {bookingEndTime(bookingDetails.appointment_start_time, bookingDetails.booking_duration)} */}
                        <TbClock /> {getdividetime(bookingDetails.appointment_start_time, bookingDetails.booking_duration, bookingDetails.staff_ids)}
                      </div>
                    </div>
                  </div>
                </div>
                <ul className="ct-cal-booking-details">
                  <li className="ct-cal-booking-details-item">
                    <label>Service</label>
                    <span>{bookingDetails.service_title}</span>
                  </li>
                  {/* <li className="ct-cal-booking-details-item">
                  <label>Methods</label>
                  <span>: Home Size</span>
                </li> */}
                  <li className="ct-cal-booking-details-item">
                    <label>Units</label>
                    <span>{bookingDetails.unit_title} </span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Addons</label>
                    <span>: {bookingDetails.addons_title ? bookingDetails.addons_title : "No Addons"}</span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Appointment notes</label>
                    <span>
                      {bookingDetails.notes === " "
                        ? " "
                        : getNotes(bookingDetails.notes)}{" "}
                    </span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Preferences</label>
                    <span>: {bookingDetails.client_preferences}</span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Booking Notes</label>
                    <span>: {bookingDetails && bookingDetails.booking_notes ? bookingDetails.booking_notes : " "}</span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Billed Amount</label>
                    <span>{bookingDetails.billed_amt}</span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Billed Hours</label>
                    <span>: {bookingDetails.booking_duration} </span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Booking Price</label>
                    <span>: {bookingDetails.booking_price}</span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Booking Discount Price</label>
                    <span>: {"$" + getCalculateddiscount(bookingDetails.booking_price_test, bookingDetails.booking_discount)}</span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Booking Discount</label>
                    <span>:{"$" + bookingDetails.booking_discount}</span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Estimated Billed Amount</label>
                    <span>: {bookingDetails.estimated_price} </span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Estimated Billed Hours</label>
                    <span>: {bookingDetails.estimated_duration} </span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Scheduled Duration</label>
                    <span>: {getscheduledtime(bookingDetails.booking_duration, bookingDetails.staff_ids, bookingDetails.order_date)} </span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Team Cleaning Time</label>
                    <span>: {bookingDetails.cleaning_time} </span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Technician Time</label>
                    <span>: {gettechnicialtime(bookingDetails.booking_timesheet_cleaning_time)}</span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Timesheet Notes</label>
                    <span>: {gettechtimesheetnotes(bookingDetails.booking_time_note)}</span>
                  </li>
                  <li className="ct-cal-booking-details-item" >
                    <label></label>
                    <Link to={`/timesheet?oid=${bookingDetails.order_id}`} className="ct-cal-booking-details-notes" target="_blank">
                      <button className="btn b-btn">Edit Timesheet Detail</button>
                    </Link>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <div className="d-flex justify-content-between align-items-center mt-4">
                      <h6 class="ct-customer-details-hr">Customer</h6>
                      <button
                        className="btn b-btn"
                        onClick={() => {
                          // getUserdata();
                          callChildFunction(bookingDetails.client_id);
                        }}
                      >
                        Edit Customer Detail
                      </button>
                    </div>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Name</label>
                    <span>{bookingDetails.client_name}</span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Email</label>
                    <span>{bookingDetails.client_email} </span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Phone</label>
                    <span>{bookingDetails.client_phone}</span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Address</label>
                    <span>: {bookingDetails.client_address}</span>
                  </li>
                  <li className="ct-cal-booking-details-item" style={{ display: "none" }}>
                    <button
                      className="btn view-more-content less-btn"
                      onClick={removeClass}
                    >
                      See Less
                    </button>
                  </li>
                  <li className="ct-cal-booking-details-item" style={{ display: "none" }}>
                    <button
                      className="btn view-more-content more-btn"
                      onClick={addClass}
                    >
                      See More
                    </button>
                  </li>
                  {/* <div className="view-more-content-hide"> */}
                  <li className="ct-cal-booking-details-item" style={{ display: "none" }}>
                    <label>Payment</label>
                    <span>: Pay Locally</span>
                  </li>
                  <li className="ct-cal-booking-details-item" style={{ display: "none" }}>
                    <label>Notes</label>
                    <span>: {bookingDetails.client_notes} </span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Family Info</label>
                    <span>: {bookingDetails.client_family_info} </span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Parking Access</label>
                    <span>: {bookingDetails.client_parking_access} </span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Client notes from tech</label>
                    <span>: {bookingDetails.client_technician_notes} </span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Special Instructions from Client</label>
                    <span>: {bookingDetails.client_special_instructions}</span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Special Instructions from Admin</label>
                    <span>: {bookingDetails.admin_special_instructions}</span>
                  </li>
                  {/* </div> */}
                  <hr />
                  <li className="ct-cal-booking-details-item">
                    <label>Assiged Staff</label>
                    <span>{getassigendstaff(bookingDetails.staff)}</span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Preferred Technician</label>
                    <span>: {bookingDetails.client_preferrend_technician == null && bookingDetails.client_preferrend_technician == "null" ? "" : bookingDetails.client_preferrend_technician}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group1">
            {bookingDetails.customer_id !== 0 && bookingDetails.customer_id !== null && bookingDetails.customer_id !== "" ? (
              <Button className="pay-Active-bttn" onClick={(e) => {
                getsubserviceaddons();
                getinvoice();
                setInvoiceprice(bookingDetails.price);
                setInvoicenotes(bookingDetails.notes)
                setCleaningTime(bookingDetails.cleaning_time);
                setBookingtimesheetcleaningtime(bookingDetails.booking_timesheet_cleaning_time);
                setBookingtimenote(bookingDetails.booking_time_note);
              }}>
                <FaMoneyBillAlt /> Bill client
              </Button>
            ) : (
              <Button className="pay-Active-bttn" onClick={createStaxId}>
                <FaMoneyBillAlt /> Create Stax Id
              </Button>
            )}
            {bookingDetails.booking_status == "CO" && bookingDetails.booking_status != "" && (
              <>
                <Button className="Delete-Active-bttn" onClick={uncomplatebooking}>
                  <FaTimesCircle /> Uncomplete
                </Button>
              </>
            )}
            {
              bookingDetails.booking_status == "C" && bookingDetails.booking_status != "" ? (

                <>
                  <Button className="confirm-Active-bttn" onClick={complatebooking}>
                    <FaCheckCircle /> Complete
                  </Button>
                  {/* {bookingDetails.customer_id !== 0 && bookingDetails.customer_id !== null && bookingDetails.customer_id !== "" ? (
                    <Button className="pay-Active-bttn" onClick={(e) => {
                      getsubserviceaddons();
                      getinvoice();
                      setInvoiceprice(bookingDetails.price);
                      setInvoicenotes(bookingDetails.notes)
                      setCleaningTime(bookingDetails.cleaning_time);
                      setBookingtimesheetcleaningtime(bookingDetails.booking_timesheet_cleaning_time);
                      setBookingtimenote(bookingDetails.booking_time_note);
                    }}>
                      <FaMoneyBillAlt /> Bill client
                    </Button>
                  ) : (
                    <Button className="pay-Active-bttn" onClick={createStaxId}>
                      <FaMoneyBillAlt /> Create Stax Id
                    </Button>
                  )} */}

                  <Button className="unconfirm-Active-bttn" onClick={unconfirmbooking}>
                    <FaRegTimesCircle /> Unconfirm
                  </Button>

                  <Button className="Delete-Active-bttn" onClick={deletebooking}>
                    <RiDeleteBin6Line /> Delete
                  </Button>
                </>
              ) : (
                ""
              )
            }{
              bookingDetails.booking_status == "A" && bookingDetails.booking_status != "" && (
                <>
                  <Button className="confirm-Active-bttn" onClick={confirmbooking}>
                    <FaCheck /> Confirm
                  </Button>
                  <Button className="reschedule-Active-bttn" onClick={rescheduleData} style={{ display: "none" }}>
                    <FaRegEdit /> Reschedule
                  </Button>

                  <Button className="reject-Active-bttn" onClick={rejectbooking} style={{ display: "none" }}>
                    <FaThumbsDown /> Reject
                  </Button>

                  <div className="delete-btn">
                    <Button className="Delete-Active-bttn" onClick={(e) => { setAddClasss(true) }}>
                      <RiDeleteBin6Line /> Delete
                    </Button>
                    <div className={"confirm-delete-modal" + (addClasss ? " show" : "")}>
                      <h3 class="popover-title">Delete this appointment?</h3>
                      <div className="popover-content">
                        <div className="btn-list">
                          <div className="btn-delete">
                            <Button className="confirm-delete" onClick={deletebooking}>
                              Delete
                            </Button>
                          </div>
                          <div className="btn-cancel">
                            <Button className="cancel-delete" onClick={(e) => { setAddClasss(false) }}>
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

            {bookingDetails.booking_status == "R" && bookingDetails.booking_status != "" && (
              <>
                <Button className="confirm-Active-bttn" onClick={activebooking}>
                  <FaToggleOn /> Active
                </Button>
              </>
            )}
            {bookingDetails.recurring_id != 0 && bookingDetails.recurring_id != null && bookingDetails.recurring_id != ""
              ? (
                <Button className="Delete-Active-bttn" onClick={deletereccuringbooking}> <RiDeleteBin6Line /> Delete all recurring </Button>
              ) : (
                // <Button className="Delete-Active-bttn" onClick={deletereccuringbooking} disabled> <RiDeleteBin6Line /> Delete all recurring </Button>
                ""
              )}

          </div>
        </Modal.Footer>
      </Modal>
      {/* edit appointment modal end */}

      <Modal show={show3} onHide={handleClose3} className="Sidebar-Modal-end">
        <Modal.Header closeButton>
          <Modal.Title>Edit Booking Details test</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="edit-booking-form">
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Service : {editbooking.service_name}</Form.Label>
                </Form.Group>
              </div>
              <div className="col-md-6" style={{ display: "none" }}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Method : Home Size</Form.Label>
                </Form.Group>
              </div>
            </div>
            <div className="row" style={{ display: "none" }}>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Billed Amount : </Form.Label>
                  <div className="input-group">
                    <span className="input-group-text">$</span>
                    <input
                      type="number"
                      placeholder="0"
                      className="form-control"
                      disabled
                      value={editbooking.billed_amount || totalAmount || 0}
                      name="billed_amount"
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Price : </Form.Label>
                  <div className="input-group">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      className="form-control"
                      value={editbooking.billed_amount}
                      name="billed_amount"
                      onChange={handleEditInputChange3}
                    />
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="row" style={{ display: "none" }}>
              <div className="col-md-12">
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Duration : </Form.Label>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <div className="input-group mb-3">
                  <span className="input-group-text">
                    <BsStopwatch />
                  </span>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="10"
                    disabled
                    value={editbooking.hours}
                  />
                  <span className="input-group-text">Hours</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-group mb-3">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="30"
                    disabled
                    value={editbooking.mints}
                  />
                  <span className="input-group-text">Minutes</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="Service-details-box-inner-title">
                  <h5>Sub Services</h5>
                </div>
              </div>
              {EditSubServices.map((subservice, index) => {
                return (
                  <div className="col-md-12">
                    <div className="Service-details-box-inner extra-service mb-3">
                      <div className="left-side">
                        <div className="d-flex align-items-center">
                          <h3 className="Service-details-box-inner-title">
                            {subservice.units_title}
                          </h3>
                        </div>
                      </div>
                      <div className="right-side">
                        <div className="d-flex align-items-center">
                          <div className="extra-service-icon">
                            <Button
                              href="#"
                              className="add-item-btn"
                              disabled={subservice.quantity == 1 ? true : false}
                              onClick={(e) => {
                                setEditstart(true);
                                changeCounter3("decrement", subservice.id);
                              }
                              }
                            >
                              -
                            </Button>
                          </div>
                          <div className="extra-service-value">
                            {subservice.quantity}
                          </div>
                          <div className="extra-service-icon">
                            <Button
                              href="#"
                              className="add-item-btn"
                              disabled={subservice.quantity == 6 ? true : false}
                              onClick={(e) => {
                                setEditstart(true);
                                changeCounter3("increment", subservice.id);
                              }
                              }
                            >
                              +
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="Service-details-box-inner-title">
                  <h5>Addons</h5>
                </div>
              </div>
              {editaddons.map((value, index) => {
                return (
                  <div className="col-md-12">
                    <div className="Service-details-box-inner extra-service mb-3">
                      <div className="left-side">
                        <div className="d-flex align-items-center">
                          <h3 className="Service-details-box-inner-title">
                            {value.addon_service_name}
                          </h3>
                        </div>
                      </div>
                      <div className="right-side">
                        <div className="d-flex align-items-center">
                          <div className="extra-service-icon">
                            <Button
                              href="#"
                              className="add-item-btn"
                              disabled={value.addons_service_qty == 0 ? true : false}
                              onClick={(e) => {
                                setEditstart(true);
                                changeeditaddons("decrement", value.id);
                              }
                              }
                            >
                              -
                            </Button>
                          </div>
                          <div className="extra-service-value">
                            {value.addons_service_qty}
                          </div>
                          <div className="extra-service-icon">
                            <Button
                              href="#"
                              className="add-item-btn"
                              disabled={value.addons_service_qty == 6 ? true : false}
                              onClick={(e) => {
                                setEditstart(true);
                                changeeditaddons("increment", value.id);
                              }
                              }
                            >
                              +
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="row">
              <div className="estimated-bottom">
                <div className="estimated-head">
                  <h5>Estimated Price && Duration</h5>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Change Billed Amount</Form.Label>
                      <div className="input-group">
                        <span className="input-group-text">$</span>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={Math.round(editbookingestimatedPrice)}
                          onChange={(e) => {
                            handlechageeditamount(e.target.value);
                          }
                          }
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Change Discount</Form.Label>
                      <div className="input-group">
                        <span className="input-group-text">$</span>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={Math.round(editbookingdiscount)}
                          onChange={(e) => {
                            handlechageeditdiscountamount(e.target.value);
                          }
                          }
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Discount Amount</Form.Label>
                      <div className="input-group">
                        <span className="input-group-text">$</span>
                        <Form.Control
                          type="text"
                          placeholder=""
                          disabled
                          value={Math.round(editbookingchangesamount)}
                          onChange={(e) => {
                            handlechageeditamount(e.target.value);
                          }
                          }
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Change Billed Hours</Form.Label>
                      <InputMask
                        mask="99:99"
                        maskChar=""
                        alwaysShowMask
                        formatChars={{ '9': '[0-9]', ':': ':' }}
                        placeholder="HH:mm"
                        className="form-control"
                        value={convertToHHMM(editbookingestimatedDuration)}
                        onChange={
                          (e) => {
                            handlechageedittime(e);
                          }
                        }
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Estimated Billed Amount...</Form.Label>

                      {
                        editbooking.booking_discount > 0 ? (
                          <Form.Control
                            type="text"
                            placeholder=""
                            value={"$" + Math.round(edittotalAmount) + " to $" + Math.round(editbookingchangesamount) || ""}
                            disabled
                          />
                        ) : (
                          <Form.Control
                            type="text"
                            placeholder=""
                            value={"$" + Math.round(edittotalAmount) + " to $" + Math.round(editbookingestimatedPrice) || ""}
                            disabled
                          />
                        )
                      }



                      {/* // <Form.Control
                          //   type="text"
                          //   placeholder=""
                          //   value={("$" + edittotalAmount + " to " + "$" + Math.round(editbookingestimatedPrice)) || ""}
                          //   disabled
                          // /> */}


                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Estimated Billed Hours==</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        disabled
                        value={(convertToHHMM(editbaseTime) + " to " + convertToHHMM(editbookingestimatedDuration)) || ""}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Client Name: </Form.Label>
                  <Form.Control type="text" placeholder="Enter Client Name" value={editbooking.client_name} name="client_name" onChange={handleEditInputChange3} />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Client Email: </Form.Label>
                  <Form.Control type="email" placeholder="Enter Client Email" value={editbooking.client_email} name="client_email" onChange={handleEditInputChange3} />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Client Address: </Form.Label>
                  <Form.Control
                    type="text"
                    as="textarea"
                    aria-label="With textarea"
                    value={editbooking.client_address}
                    name="client_address"
                    onChange={handleEditInputChange3}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Appointment City: </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Appointment City"
                    value={editbooking.appointment_city}
                    name="appointment_city"
                    onChange={handleEditInputChange3}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Appointment State: </Form.Label>
                  {/* <Form.Control
                  type="text"
                  placeholder="Enter Appointment State"
                  value={editbooking.appointment_state}
                  name="appointment_state"
                  onChange={handleEditInputChange3}
                /> */}

                  <Form.Select aria-label="Default select example" className="custom-select-box" onChange={
                    (e) => { setEditbookingstate(e.target.value) }
                  } name="state">
                    {states.map((state) => (
                      <option value={state.stateCode} selected={editbooking.appointment_state == state.stateCode}>{state.name}</option>
                    ))}

                  </Form.Select>

                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Appointment Zip: </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Appointment Zip"
                    value={editbooking.appointment_zip}
                    name="appointment_zip"
                    onChange={handleEditInputChange3}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Assign Appointment to Staff</Form.Label>
                  {
                    selectedOptions && (
                      <Select
                        isMulti
                        defaultValue={selectedOptions}
                        options={staffList}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) => {
                          setAssignStaff(e)
                        }}
                      />

                    )
                  }


                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Appointment Notes: </Form.Label>
                  <Form.Control
                    type="text"
                    as="textarea"
                    aria-label="With textarea"
                    value={editbooking.appointment_note}
                    name="appointment_note"
                    onChange={handleEditInputChange3}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Start Date: </Form.Label>
                  <Form.Control type="date" value={EditbookingStartDate || ""} onChange={
                    (e) => {
                      setEditbookingStartDate(e.target.value)
                    }
                  } />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Start Time: </Form.Label>
                  <Form.Control type="time" value={EditbookingStartTime || ""} onChange={
                    // (e) => {
                    //   setEditbookingStartTime(e.target.value)
                    // }
                    handleStartTimeChange
                  } />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>End Date: </Form.Label>
                  <Form.Control type="date" value={EditbookingStartDate || ""} onChange={
                    (e) => {
                      setEditbookingStartDate(e.target.value)
                    }
                  } />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>End Time: </Form.Label>
                  <Form.Control type="time" value={
                    // moment(editbooking.start_time, "HH:mm:ss").add(expedtedendtime, "minutes").format("HH:mm:ss")
                    // getCalculatedEndTime(editbooking.start_time, expedtedendtime)
                    editbookingtimeEnd
                  } />
                </Form.Group>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Cancel
          </Button>
          <Button variant="primary" onClick={UpdateBookigData}>
            Update
          </Button>
          {editbooking.recurring_id && editbooking.recurring_id != 0 && editbooking.recurring_id !== null && editbooking.recurring_id !== "" ? (
            <Button className="primary btn-big-width" onClick={updaterecurringbooking}>{apicall ? <Spinner animation="border" /> : "Update all recurring"}</Button>
          ) : ''}
        </Modal.Footer>
      </Modal>

      {
        usercrmid != 0 ? (
          <ChildComponent id={usercrmid} onCloseidChange={(editid) => setUsercrmid(editid)} />
        ) : (
          <></>
        )

      }

      <NotificationContainer />
      {/* </>
        )} */}
    </div>
  );
}

function convertToHHMM(value) {
  const start_time = value;
  const hours = Math.floor(start_time / 60);
  const minutes = start_time % 60;

  // Format hours and minutes as two-digit numbers
  const formattedHours = hours < 10 ? '0' + hours : hours.toString();
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes.toString();

  // Combine hours and minutes in the "00:00" format
  const formattedTime = formattedHours + ':' + formattedMinutes;
  return formattedTime;
}

function getpriceperhours(amount, baseminutes) {
  if (amount != 0 && baseminutes != 0) {
    const hours = baseminutes / 60;

    const priceperhours = amount / hours.toFixed(2);
    return priceperhours.toFixed(2);
  } else {
    return "0.00";
  }
}

function getCalculateddiscount(booking_price, booking_discount) {
  if (booking_price != 0 && booking_discount != 0) {
    const discount = parseInt(booking_price) - parseInt(booking_discount);
    return discount;
  } else {
    return "0.00";
  }
}

const calculateTotal = (rows, discount) => {
  let total = rows.reduce((acc, row) => acc + row.total, 0);
  total -= parseFloat(discount) || 0;
  // setcalculateTotal(total);
  return total;
};


export default Appointment;