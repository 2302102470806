import axios from "axios";
import { API_URL } from "./config.js";
import {apiCall} from "./auth";

//userslist
export const userslist = (searchkeyword,pageno) => apiCall(`${API_URL}getusers?key=${searchkeyword}&pageno=${pageno}`, "GET", );

//getdata
export const getdata = (formData) => apiCall(`${API_URL}getdata`, "POST", formData);

//getactivestaff
export const getactivestaff = () => apiCall(`${API_URL}getactivestaff`, "GET", );

//getservicesdata
export const getbookingservices = (userType) => apiCall(`${API_URL}getbookingservices?userType=${userType}`, "GET", );

//getbookingservicesApi
export const getbookingservicesApi = (formData) => apiCall(`${API_URL}getbookingservicesApi`, "POST",formData);

//getsubservicebyserives
export const getsubservicebyservice = (formData) => apiCall(`${API_URL}getsubservicebyservice`, "POST", formData); 

//getsubservicebyserives
export const getsubservicebyserviceApi = (formData) => apiCall(`${API_URL}getsubservicebyserviceApi`, "POST", formData); 

//addbooking
export const addbooking = (formData) => apiCall(`${API_URL}addbooking`, "POST", formData);

//getbookings  ServiceId, zoneId, appOption, userType
// export const getbookings = (date,serviceid,zoneid,appOption,usertype) => apiCall(`${API_URL}getbookings?start_date=${date}&serviceid=${serviceid}&zoneid=${zoneid}&option=${appOption}&usertype=${usertype}`, "GET", );
export const getbookings = (date,type) => apiCall(`${API_URL}getbookings?start_date=${date}&type=${type}`, "GET", );

//getactivestaff
export const getStaff = (changestaffzone) => apiCall(`${API_URL}getstaff?zoneid=${changestaffzone}`, "GET", );

//getBookingById
export const getBookingById = (formData) => apiCall(`${API_URL}getbookingdata`, "POST", formData);

//getuserData
export const getuserData = (formData) => apiCall(`${API_URL}getuserData`, "POST", formData);

//getEditbookingData
export const getEditbookingData = (formData) => apiCall(`${API_URL}getEditbookingData`, "POST", formData);

//updateBookingData
export const updateBookingData = (formData) => apiCall(`${API_URL}updateBookingDetails`, "POST", formData);

//updateBookingData
export const updaterecurringbookingAPi = (formData) => apiCall(`${API_URL}updaterecurringbooking`, "POST", formData);

//getactivestaff
export const calculatePriceApi = (formData) => apiCall(`${API_URL}calculatePriceApi`, "POST",formData);

//confirmbookingstatus 
export const confirmbookingstatus = (formData) => apiCall(`${API_URL}confirmbookingstatus`, "POST",formData);

//confirmbookingstatus 
export const rejectbookingstatus = (formData) => apiCall(`${API_URL}rejectbookingstatus`, "POST",formData);

//confirmbookingstatus 
export const deletebookingApi = (formData) => apiCall(`${API_URL}deletebooking`, "POST",formData);

//confirmbookingstatus 
export const reccuringdeletebooking = (formData) => apiCall(`${API_URL}reccuringdeletebooking`, "POST",formData);

//confirmbookingstatus 
export const getrescheduledata = (formData) => apiCall(`${API_URL}getrescheduledata`, "POST",formData);

//confirmbookingstatus 
export const saverescheduledata = (formData) => apiCall(`${API_URL}saverescheduledata`, "POST",formData);

//getDates 
export const getDates = (selectedDays) => apiCall(`${API_URL}getDates`, "POST",selectedDays);

//getaddon 
export const getaddon = (formData) => apiCall(`${API_URL}getaddonbyaservice`, "POST",formData);

//getaddon 
export const getZone = () => apiCall(`${API_URL}getZone`, "GET",);

//getaddon 
export const createStaxId = (formData) => apiCall(`${API_URL}createStaxId`, "POST",formData);

//getpaymentMethodApi 
export const getpaymentMethodApi = (formData) => apiCall(`${API_URL}getpaymentMethoduser`, "POST",formData);

//unconfirmbookingstatus 
export const unconfirmbookingstatus = (formData) => apiCall(`${API_URL}unconfirmbookingstatus`, "POST",formData);

//complatebookingstatus 
export const complatebookingstatus = (formData) => apiCall(`${API_URL}complatebookingstatus`, "POST",formData);

//uncomplatebookingstatus 
export const uncomplatebookingstatus = (formData) => apiCall(`${API_URL}uncomplatebookingstatus`, "POST",formData);

//uncomplatebookingstatus 
export const activebookingstatus = (formData) => apiCall(`${API_URL}activebookingstatus`, "POST",formData);

//uncomplatebookingstatus 
export const getservicepreference = (formData) => apiCall(`${API_URL}getservicepreference`, "POST",formData);

//uncomplatebookingstatus 
export const updateservicepreferenceApi = (formData) => apiCall(`${API_URL}updateservicepreferenceApi`, "POST",formData);

//createStaxId 
export const createStaxIdApi = (formData) => apiCall(`${API_URL}createStaxId`, "POST",formData);

//createinvoiceApi 
export const createinvoiceApi = (formData) => apiCall(`${API_URL}createinvoice`, "POST",formData);

//createinvoiceApi 
export const getsubserviceaddonsApi = (formData) => apiCall(`${API_URL}getsubserviceaddons`, "POST",formData);

//createinvoiceApi 
export const getinvoiceApi = (order_id) => apiCall(`${API_URL}getinvoice?order_id=${order_id}`, "GET",[]);

//addpaymentmethods
export const createPaymentMethod = (formData) =>apiCall(`${API_URL}createPaymentMethod`, "POST", formData);

//getpaymentMethod 
export const getpaymentMethod = (formData) => apiCall(`${API_URL}getpaymentMethod`, "POST", formData);

//payinvoice 
export const payinvoiceApi = (formData) => apiCall(`${API_URL}payinvoice`, "POST", formData);

//payinvoice 
export const checkemailApi = (formData) => apiCall(`${API_URL}checkemail`, "POST", formData);

//payinvoice 
export const getpreferredstaffApi = () => apiCall(`${API_URL}getpreferredtechnician`, "POST", );

//getcoordinatesData 
export const getcoordinatesData = (selectedStaff,startdate,enddate,searchValue,changestaffzone) => apiCall(`${API_URL}getcoordinatesData?selectedStaff=${selectedStaff}&startdate=${startdate}&enddate=${enddate}&searchValue=${searchValue}&changestaffzone=${changestaffzone}`, "POST", );

//gettechapps 
export const gettechapps = (selectedStaff,startdate,enddate,searchValue,changestaffzone) => apiCall(`${API_URL}gettechapps?selectedStaff=${selectedStaff}&startdate=${startdate}&enddate=${enddate}&searchValue=${searchValue}&changestaffzone=${changestaffzone}`, "POST", );

//gettechapps 
export const getmissingappointment = (selectedStaff,startdate,enddate,searchValue,changestaffzone) => apiCall(`${API_URL}getmissingappointment?selectedStaff=${selectedStaff}&startdate=${startdate}&enddate=${enddate}&searchValue=${searchValue}&changestaffzone=${changestaffzone}`, "POST", );

//updateBookingtime 
export const updateBookingtime = (formData) => apiCall(`${API_URL}updateBookingtime`, "POST",formData);

//updateBookingtime 
export const findzonebyzipApi = (formData) => apiCall(`${API_URL}findzonebyzipApi`, "POST",formData);
//updateBookingtime 
export const getstafflistwithlatlang = (formData) => apiCall(`${API_URL}getstafflistlatlang`, "POST",formData);

//updateBookingtime 
export const getziplistbyzone = (formData) => apiCall(`${API_URL}getziplistbyzone`, "POST",formData);

//getproductlistdataApi 
export const getproductlistdataApi = () => apiCall(`${API_URL}productlistdata`, "GET",[]);


//getreviewlist 
export const getreviewlist = () => apiCall(`${API_URL}getreviewlist`, "GET",[]);

//savereviewdata 
export const savereviewdata = (formData) => apiCall(`${API_URL}savereviewdata`, "POST",formData);

//savereviewdata 
export const updatecrmdataApi = (formData) => apiCall(`${API_URL}updatecrmdetails`, "POST",formData);

//sendemailpasswordapi 
export const sendemailpasswordapi = (formData) => apiCall(`${API_URL}sendemailpasswordtouser`, "POST",formData);


//getsinglereview 
export const getsinglereview = (formData) => apiCall(`${API_URL}getsinglereview`, "POST",formData);

//create_keap_notes 
export const create_keap_notes = (formData) => apiCall(`${API_URL}create_keap_notes`, "POST",formData);

//update_keap_notes 
export const update_keap_notes = (formData) => apiCall(`${API_URL}update_keap_notes`, "POST",formData);


//getsearchpricestatus 
export const getsearchpricestatus = (formData) => apiCall(`${API_URL}getsearchpricestatus`, "POST",formData);

//getsearchpricestatus 
export const smsstatusforuser = (formData) => apiCall(`${API_URL}smsstatusforuser`, "POST",formData);

//emailstatusforuser 
export const emailstatusforuser = (formData) => apiCall(`${API_URL}emailstatusforuser`, "POST",formData);

//getdefaultpayment 
export const getdefaultpayment = (formData) => apiCall(`${API_URL}getdefaultpayment`, "POST", formData);

//updatepaymentMethod 
export const updatepaymentMethod = (formData) => apiCall(`${API_URL}updatepaymentMethod`, "POST", formData);

//reviewtosite 
export const reviewtosite = (formData) => apiCall(`${API_URL}reviewtosite`, "POST", formData);

//upatereviewtosite 
export const upatereviewtosite = (formData) => apiCall(`${API_URL}upatereviewtosite`, "POST", formData);

//upatereviewtosite 
export const addbookingbookingstatus = (formData) => apiCall(`${API_URL}activebookingstatus`, "POST", formData);

//upatereviewtosite 
export const getservicesdataApi = (formData) => apiCall(`${API_URL}getbookingsdetails`, "POST", formData);

//upatereviewtosite 
export const updateUserEmail = (formData) => apiCall(`${API_URL}updateUserEmail`, "POST", formData);

//upatereviewtosite 
export const getpricingdata = (formData) => apiCall(`${API_URL}getpricingdata`, "POST", formData);

export const updatePricingData = (formData) => apiCall(`${API_URL}updatePricingData`, "POST", formData);


