import React, { useState, useEffect } from "react";
import Informasi from "../Components/ServiceDataTable/Informasi";
import Header from "../Components/Header/Header";
import Sidebar from "../Components/Sidebar/Sidebar";
import "./Services.css";
import { RiAddFill } from "react-icons/ri";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { addService } from "../Api/servicesApi";
import { Roller } from "react-awesome-spinners";
import Button from "react-bootstrap/Button";
import Spinner from 'react-bootstrap/Spinner';


function Services() {

  const [show07, setShow07] = useState(false);

  const [serviceTitle, setServiceTitle] = useState("");
  const [serviceDescription, setServiceDescription] = useState("");
  const [serviceAvailability, setServiceAvailability] = useState("");
  const [servicePercentage, setServicePercentage] = useState("");

  const [hourlyrate , sethoulryrate] = useState(0);
  const [basePrice , setBasePrice] = useState(0);
   
  const [loading, setLoading] = useState(false);
  const [spinnerloading, setSpinnerLoading] = useState(false);

  const [showError, setShowError] = useState(false)



  const [serviceImage, setServiceImage] = useState(null);


  const handleClose07 = () => {
    setShow07(false);
    setShowError(false)
  }

  const handleShow07 = () => setShow07(true);

  
  //set service image
  const handleServiceImage = (e) => {
    const newImage = e.target.files[0];
    setServiceImage(newImage);
  };


  const savaService = () => {
    setSpinnerLoading(true);
    const formData = new FormData();
    formData.append("service_title", serviceTitle);
    formData.append("service_description", serviceDescription);
    formData.append("image", serviceImage);
    formData.append("service_availability", serviceAvailability);
    formData.append("service_percentage", servicePercentage);
    formData.append("hourlyrate", hourlyrate);
    formData.append("basePrice", basePrice);

    if (serviceTitle == '' ||
      serviceDescription == '') {
      setShowError(true);
      setSpinnerLoading(false);
    } else {
      addService(formData).then((res) => {
        setLoading(true);
        if (res.code === 200) {
          setLoading(false);
          alert("Service added successfully");
          window.location.reload();
        }
        else {
          alert("Something went wrong");
        }
      }).catch((err) => {
        console.log("err", err);
      }).finally(() => {
        setSpinnerLoading(false);
      })

    }




  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);




  return (
    <div>
      {
        loading ? (
          <div className="loading">
            <Roller />
          </div>
        ) : (<>
          {/* Add Service modal start here */}
          <Modal show={show07} onHide={handleClose07} className="Sidebar-Modal-end">
            <Modal.Header closeButton>
              <Modal.Title>Create Service</Modal.Title>
            </Modal.Header>
            <Modal.Body className="custum-modal-body">
              <form id="Create Service">
                <div className="row">
                  <div className="col-md-12">
                    <div className="logo-content">
                      <div className="add-logo">
                        <div className="image-preview">
                          {serviceImage ? (
                            <img
                              src={URL.createObjectURL(serviceImage)}
                              alt="logo"
                              className="profile-img"
                            />
                          ) : (
                            <div className="loading-screen">
                              <span className="loader">Service logo</span>
                            </div>
                          )}
                        </div>
                        <div className="mt-2">
                          <div className="form-group block-upload">
                            <div className="upload-logo">
                              <div className="custom-file">
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  id="customFile"
                                  alt="Choose"
                                  onChange={handleServiceImage}
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="customFile"
                                >
                                  Choose
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group className="mb-3 mt-3" controlId="formBasicEmail">
                      <Form.Label>Service Title</Form.Label>
                      <Form.Control type="text" placeholder="" onChange={(e) => setServiceTitle(e.target.value)} />
                      {showError && serviceTitle == '' ? <p style={{ color: 'red' }}>this field is required</p> : ''}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Service Description</Form.Label>
                      <Form.Control type="text" placeholder="" name="service_descripation" onChange={(e) => setServiceDescription(e.target.value)} />
                      {showError && serviceDescription == '' ? <p style={{ color: 'red' }}>this field is required</p> : ''}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Services Availability</Form.Label>
                      <Form.Select aria-label="Default select example" onChange={
                        (e) => {
                          setServiceAvailability(e.target.value)
                        }} >
                        <option value="0">New Client</option>
                        <option value="1">Existing Client</option>
                        <option value="2">Both</option>
                        <option value="3">Admin Service</option>
                      </Form.Select>
                      {serviceAvailability == '' ? <p style={{ color: 'red' }}>this field is required</p> : ''}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Service Percentage</Form.Label>
                      <Form.Control type="text" placeholder="" value={servicePercentage} onChange={(e) => setServicePercentage(e.target.value)} />
                      {showError && servicePercentage == '' ? <p style={{ color: 'red' }}>this field is required</p> : ''}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Base Price</Form.Label>
                      <Form.Control type="number" placeholder="" value={hourlyrate} onChange={(e) => sethoulryrate(e.target.value)} /> 
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Hourly Rate</Form.Label>
                      <Form.Control type="number" placeholder="" value={basePrice} onChange={(e) => setBasePrice(e.target.value)} /> 
                    </Form.Group>
                  </div>
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer>
              {
                spinnerloading ? (
                  <Button className="Active-bttn btn" disabled>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </Button>
                ) : (
                  <button className="Active-bttn btn" onClick={savaService}>
                    {" "}
                    Save{" "}
                  </button>
                )
              }
              <button className="Cancel-bttn btn" onClick={handleClose07}>
                {" "}
                Cancel{" "}
              </button>
            </Modal.Footer>
          </Modal>
          {/* modal End here */}

          <div className="dashboard-container d-flex">
            <div className="sidebar-container">
              <Sidebar />
            </div>
            <div className="main-container">
              <div className="main-page-body-content">
                {/* <div className="main-page-body-content-header">
                  <Header />
                </div> */}
                <div className="main-page-body-content-body">
                  <div className="main-page-body-content-body-container">
                    <div className="container-fulid">
                      <div className="">
                        <div className="table-card ">
                          <div className="card-body table-card-body">
                            <div className="table-card-header mb-4 d-flex justify-content-between">
                              <h5 className="card-title">All Services</h5>
                              <button
                                className="btn btn-primary d-flex align-items-center btn-sm add-user-btn"
                                onClick={handleShow07}
                              >
                                <RiAddFill />
                                Add Service
                              </button>
                            </div>
                            <div className="table-card-body-inner">
                              <Informasi />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
        )}
    </div>
  );
}

export default Services;
